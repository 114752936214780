@import "../colors.scss";

.case-initialize-main {
  margin: 1rem 10%;
  padding: 1.5rem 0rem;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10rem;
  #init-dropdowns-row {
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
  .init-dropdown {
    width: 80%;
    margin-left: 35px;
    @media (max-width: 1200px) {
      margin-left: 50px;
      padding-left:2%;
      margin: auto;
      width: 50%;
    }
  }
  .init-dropdown-data {
    width: 80%;
    margin-left: 50px;
    @media (max-width: 1200px) {
      padding-left:2%;
      margin: auto;
      width: 50%;
    }
  }
  .select-target-column-header {
    margin: 0rem 0rem 1rem 0;
    font-size: 1.0rem;
    font-weight: normal;
    width: 100%;
    display: block;
    align-items: center;
    .select-target-icon {
      &:hover {
        cursor: pointer;
      }
    }
    @media (max-width: 600px) {
      font-size: .9rem;
    }
    @media (max-width: 1200px) {
      margin-top: 30px;
      margin-left: 15px;
    }
  }
  .select-target-column-inputs {
    width: 100%;
    display: block;
    align-items: center;
    .select-target-column {
      width: 100%;
      max-width: 100%;
      margin-right: 2rem;
      margin-left: 1rem;
      &.error {
        border: 1px solid $error-color;
      }
      @media (max-width: 1200px) {
        margin: auto;
      }
    }
    .select-problem-type {
      width: 20rem;
      max-width: 40%;
      margin-left: 1rem;
    }
  }
  .problem-type-input {
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1090px) {
      font-size: 0.85rem;
    }
    .select-target-column {
      width: 100%;
      max-width: 100%;
      margin-right: 2rem;
      margin-left: 1rem;
      &.error {
        border: 1px solid $error-color;
      }
    }
    .problem-type-input {
      width: 20rem;
      max-width: 100%;
      margin-left: 1rem;
    }
  }
  #problem-type-input-container {
    width: 150px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  #previewSpan {
    color: #007bff;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 72%;
    @media (max-width: 1200px) {
      margin-left: 62%;
    }
  }
  .learnSpan {
    color: #007bff;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 0.5%;
  }
  .stepBadge {
    margin-right: 1.2rem;
    font-size: 1.1rem;
    border-radius: 100%;
  }
}
#caseInitBackendRTable {
  width: 90%;
  margin: auto;
}
#backendRTableHeader {
  font-size: 1rem;
  margin-left:5%
}
.search-file-header-bar {
  width: 100%;
  position: relative;
  .search-box {
    position: absolute;
    width: 30rem;
    max-width: 35%;
  }
  .header-text {
    font-size: 1rem;
    font-weight: normal;
  }
  .header-text-left-aligned {
    font-size: 1rem;
    font-weight: normal;
    display: block;
    align-items: center;
    @media (max-width: 600px) {
      font-size: .9rem;
    }
  }
  #problemTypeRow {
    margin-top: 500px;
  }
}