@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

.table-default {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background-color: white;
  .table-wrap {
    display: block;
    max-width: 99.9%;
    text-align: center;
    table {
      width: 99.7%;
      margin-bottom: 0.1rem;
      border-spacing: 0;
      tr {
        border-bottom: 1px solid gray;
        &:hover {
          td {
            background-color: rgba($primary-color, 0.05);
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.8rem 0.5rem;
        width: 1%;
        word-wrap: break-word;
        border-bottom: 2px solid #ddd;
        &.fit-content {
          width: 0.0000001%;
          white-space: nowrap;
        }
      }

      th {
        color: gray;
        border-bottom: 2px solid gray;
      }
    }
  }
  &.left-aligned {
    .table-wrap {
      text-align: left;
      table {
        td,
        th {
          padding: 0.8rem 1rem;
        }
      }
    }
  }

  &.row-selectable {
    table {
      tr {
        th:first-child,
        td:first-child {
          padding-left: 1.5rem;
        }
        &.tr-selected {
          border: 3px solid $primary-color;
          td:first-child {
            position: relative;
            &::before {
              display: flex;
              align-items: center;
              position: absolute;
              top: 0;
              height: 100%;
              left: 0.5rem;
              width: 0.000001%;
              content: "✓";
              color: green;
            }
          }
        }
      }
    }
  }
}

.table-display {
  display: block;
  width: 100%;
  margin-top: 1rem;
  .table-wrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    text-align: center;
    table {
      width: 99.99%;

      tr {
        &:nth-child(even) {
          background: #f6f8f9;
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        width: 1%;
        &.fit-content {
          width: 0.0000001%;
        }
        &:first-child {
          font-weight: bold;
        }
      }

      th {
        background: rgba($primary-color, 0.4);
      }
    }
  }
}

.table-preprocessing {
  display: block;
  width: 100%;
  margin-top: 1rem;
  position: relative;
  .table-wrap {
    display: block;
    min-height: 10rem;
    max-width: 100%;
    overflow: auto;
    text-align: center;
    table {
      width: 99.99%;
      border-spacing: 0;

      tr {
        &:nth-child(even) {
          background: rgba(0, 0, 0, 0.1);
        }
        &.tr-selected {
          background: rgba($primary-color, 0.4);
          color: white;
        }
      }
      td {
        margin: 0;
        padding: 0.5rem;
        width: 1%;
        &.fit-content {
          width: 0.0000001%;
        }
      }
      .no-histogram {
        background: rgba($primary-color, 0.5);
        border-bottom: 2px solid black;
        padding: 0.5rem;
      }
      th {
        .preprocess-hist-container {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); //hist only
          .no-histogram-tooltip {
            opacity: 1 !important;
            background: #ffffff !important;
            color: #000 !important;
            border-top: 2px solid #f39b12 !important;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
          }
          .hist-classification-column {
            min-width: 190px;
            height: 157px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
        .preprocess-table-header {
          background: rgba($primary-color, 0.5); //hist only
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 240px;
          }
        }
      }
      .td-selected {
        background-color: rgba($primary-color, 0.2);
      }
      .td-added {
        background-color: rgba($success-color, 0.2);
      }
      .td-modified {
        background-color: rgba($warning-color, 0.2);
      }
    }
  }
}
.green-prediction-cell {
  background-color: rgba(20, 153, 8, 0.2);
}
.golden-prediction-cell {
  background-color: rgba(255, 166, 0, 0.2);
}
.table-filter {
  width: 101%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-filter-multi-selector {
  width: 100%;
  margin: 1rem;
  height: 1.2rem;
  min-width: 7rem;
}

.table-filter-rangeslider {
  margin: 1rem 2rem;
  width: 5rem !important;
  color: white;
  min-width: 4rem;
}

.table-editable-cell {
  .table-edit-cell-icon {
    display: none;
  }
  &:hover {
    .table-edit-cell-icon {
      display: inline-block;
    }
  }
}

.table-cell-modified {
  color: $warning-color;
}

.cell-with-icons {
  width: 100%;
  display: flex;
  align-items: center;
  .cell-content {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .cell-icon {
    margin-right: 1rem;
    cursor: pointer;
  }
}

.cell-default {
  &::before {
    content: "*";
    font-size: 1.2rem;
    font-weight: bold;
    color: $warning-color;
    margin-right: 0.2rem;
  }
}

.help-icon-importance {
  height: 0.9rem;
  width: 0.9rem;
  margin-left: 0.4rem;
}

.table-hyperparams {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background-color: white;
  .table-wrap {
    display: block;
    max-width: 99.9%;
    overflow-x: auto;
    table {
      width: 99.9%;
      margin-bottom: 0.1rem;
      border-spacing: 0;
      th,
      td {
        margin: 0;
        padding: 1rem 1.5rem;
        width: 1%;
        word-wrap: break-word;
        .slider,
        .selector {
          margin-left: auto;
          margin-right: auto;
          max-width: 20rem;
        }
        .setting-input {
          text-align: center;
        }
        &.fit-content {
          width: 0.0000001%;
          white-space: nowrap;
        }
      }

      th {
        text-align: center;
        border-bottom: 2px solid $secondary-color;
      }
    }
  }
}

.opt-table-hyperparams {
  display: block;
  width: 100%;
  margin-top: 1rem;
  background-color: white;
  .table-wrap {
    display: block;
    max-width: 99.9%;
    overflow-x: visible;
    table {
      width: 99.9%;
      margin-bottom: 0.1rem;
      border-spacing: 0;
      th,
      td {
        margin: 0;
        padding: 1rem 1.5rem;
        width: 1%;
        word-wrap: break-word;
        .slider,
        .selector {
          margin-left: auto;
          margin-right: auto;
          max-width: 20rem;
        }
        .setting-input {
          text-align: center;
        }
        &.fit-content {
          width: 0.0000001%;
          white-space: nowrap;
        }
      }

      th {
        text-align: center;
        border-bottom: 2px solid $secondary-color;
      }
    }
  }
}

.table-model-metric {
  display: block;
  width: 94%;
  margin: 0 3% 3% 1rem;
  .table-wrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    text-align: center;
    table {
      margin: 1%;
      width: 98%;
      tbody {
        border: 1px dashed gray;
        tr {
          &:nth-child(1) {
            font-size: 1.2rem;
            height: 4rem;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        &:first-child {
          font-weight: bold;
          text-align: left;
          padding-left: 5%;
          width: 18rem;
        }
      }
    }
  }
}

.table-opt {
  display: block;
  width: 60%;
  margin: auto;
  .table-wrap {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    text-align: center;
    table {
      margin: 1%;
      width: 98%;
      tbody {
        border: 2px dashed gray;
        tr {
          &:nth-child(1) {
            font-size: 1rem;
            height: 1rem;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        &:first-child {
          // font-weight: bold;
          text-align: center;
          padding-left: 5%;
          width: 18rem;
        }
      }
      th {
        color: #d84f00;
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }
}
