.membership2 {
  .container {
    @media screen and (min-width: 1500px) {
      max-width: 1500px;
    }
    @media screen and (min-width: 1600px) {
      max-width: 1600px;
    }
    .memb-content {
      display: grid;
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
      column-gap: 1rem;
      margin-top: 2rem;
      .col-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #d8d8d8;
        width: 100%;
        border-radius: 8px;
        &.no-border-radius {
          border-radius: 0px;
        }
        .memb-attr {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          height: 6.5rem;
          justify-content: center;
          padding-left: 1rem;
          font: Medium 14px/19px Roboto;
          letter-spacing: 0.7px;
          color: #010e2a;
        }
        .memb-row {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          height: 6.5rem;
          justify-content: center;

          .col-1-title {
            background: #0076c0 0% 0% no-repeat padding-box;
            width: 100%;
            height: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font: Bold 24px/35px Poppins;
            letter-spacing: 0px;
            color: #ffffff;
          }
          .col-title {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .col-2-title-text {
              padding-top: 0.5rem;
              font: bold 24px/35px Poppins;
              letter-spacing: 0px;
              color: #010e2a;
            }
          }
          .memb-check {
            display: flex;
            align-items: center;
            border-left: 1px solid #d3d3d3;
            width: 100%;
            justify-content: center;
            height: 100%;
            letter-spacing: 2.49px;
            &__icon {
              flex-shrink: 0;
              width: 30px;
              height: 30px;
              background: #459bd1 0% 0% no-repeat padding-box;
              border-radius: 25px;
              color: white;
              text-align: center;
              margin-top: 0.5rem;
              padding-top: 4px;
            }
          }
          .memb-contact {
            padding: 0.75rem 2rem;
            background: #ff5e1400 0% 0% no-repeat padding-box;
            border: 2px solid #ff5e14;
            border-radius: 6px;
            color: #ff5e14;
            font: Medium 16px/25px Poppins;
            letter-spacing: 0px;
            &:hover {
              cursor: pointer;
              background: #ff5e14 0% 0% no-repeat padding-box;
              color: #ffffff;
            }
            &.memb-business {
              background: #ff5e14 0% 0% no-repeat padding-box;
              color: #ffffff;
              &:hover {
                background: lighten(#ff5e14, 15%);
                border-color: lighten(#ff5e14, 15%);
              }
            }
          }
        }
        &:last-child {
          border: 2px dashed #ff5e14;
        }
      }
    }
  }
}
