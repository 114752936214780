$product-color: #0076c0;
$product-forecolor: #ffffff;
$product-secondary-color: #1d588f;
$bright-blue: #007aff;
// Report colors
$yellow: #f8b715;
$flagyellow: #f0c40f;
$navy: #0d547b;
$dark-grey: #646464;
$light-grey: #b1b1b1;
$grey-blue: #0f5074;
$orange: #ff5e14;
$light-blue: #10cdff;

// Universal colors
$primary-color: #3c86ff;
$secondary-color: #43425d;
$error-color: #c64949;
$success-color: #149908;
$warning-color: #ffa600;
$disabled-color: #efefef;

// Local variables
$primary-text-color: #4d4f5c;
$inverse-text-color: #ffffff;

$primary-button-color: #3fcda6;
$primary-button-text-color: #ffffff;

.error-color {
  color: $error-color;
}
.warning-color {
  color: $warning-color;
}
.primary-color {
  color: $primary-color;
}
.success-color {
  color: $success-color;
}
.disabled-color {
  color: $disabled-color;
}

// $color-order: red, orange, yellow, green, blue, purple;
$color-order: #3fcda6, #00caff, #5a51dd, #f39b12, #f0c40f, #8bc34a;

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// colors for ml models
$model-colors: (
  randomforest: #f59684,
  extratrees: #f59684,
  xgboost: #40c1ad,
  knn: #f8579a,
  adaboost: #f8579a,
  svm: #8f2ac8,
  decisiontree: #4d4a8f,
  ridge: #4d4a8f,
  gradientboosting: #651b74,
  lasso: #651b74,
  fcnn: #0d6879,
);

@each $color-key, $color-value in $model-colors {
  .#{$color-key} {
    color: $color-value !important;
  }
  .bg-#{$color-key} {
    background: transparent
      linear-gradient(325deg, $color-value 0%, lighten($color-value, 10%) 99%)
      0%
      0%
      no-repeat
      padding-box;
  }
  .border-#{$color-key} {
    tbody {
      tr {
        &:nth-child(1) {
          border: 3px solid $color-value !important;
        }
      }
    }
  }
  .tab-#{$color-key} {
    .tab-option {
      &.selected {
        color: $color-value !important;
      }
    }
  }
}
