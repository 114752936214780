.membership {
  .container {
    @media screen and (min-width: 1500px) {
      max-width: 1500px;
    }
    @media screen and (min-width: 1600px) {
      max-width: 1600px;
    }
    .mem-top-title {
      font-size: 2rem;
      letter-spacing: 0px;
      color: #2e3b44;
      opacity: 1;
      padding: 1rem 0;
      text-align: center;
    }
    .membership-content {
      width: 100%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d3d3d3;
      border-radius: 10px;
      opacity: 1;
      padding: 2rem 4rem 4rem 4rem;
      .mem-classes {
        display: grid;
        grid-template-columns: 4fr repeat(4, 1fr);
        grid-auto-rows: 4rem;
        align-items: center;
        justify-items: center;
        .mem-title {
          display: flex;
          letter-spacing: 0px;
          color: #ffffff;
          border-radius: 1px;
          justify-content: center;
          height: 100%;
          width: 100%;
          align-items: center;
          overflow: hidden;
          font-size: 1.25rem;
          @media screen and (max-width: 1200px) {
            font-size: 0.75rem;
          }
          &.trial-title {
            grid-column-start: 2;
            background: #eaa210 0% 0% no-repeat padding-box;
            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f0d7";
              position: absolute;
              top: 220px;
              color: #eaa210;
              font-size: 30px;
            }
          }
          &.standard-title {
            background: #89d4d2 0% 0% no-repeat padding-box;
            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f0d7";
              position: absolute;
              top: 220px;
              color: #89d4d2;
              font-size: 30px;
            }
          }
          &.prof-title {
            background: #df495c 0% 0% no-repeat padding-box;
            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f0d7";
              position: absolute;
              top: 220px;
              color: #df495c;
              font-size: 30px;
            }
          }
          &.prem-title {
            background: #1aceb3 0% 0% no-repeat padding-box;
            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f0d7";
              position: absolute;
              top: 220px;
              color: #1aceb3;
              font-size: 30px;
            }
          }
        }
        .standard-title {
          display: flex;
          background: #89d4d2 0% 0% no-repeat padding-box;
          justify-content: center;
          height: 100%;
          width: 100%;
          align-items: center;
        }
      }
      .mem-wrapper {
        border: 1px solid #d3d3d3;

        .mem-row {
          display: grid;
          grid-template-columns: 4fr repeat(4, 1fr);
          grid-auto-rows: minmax(4rem, auto);
          align-items: center;
          justify-items: center;
          border-bottom: 1px solid #d3d3d3;
          &:last-child {
            border-bottom: none;
          }
          &:nth-child(even) {
            background: #f1f1f1 0% 0% no-repeat padding-box;
          }
          .mem-check {
            display: flex;
            align-items: center;
            border-left: 1px solid #d3d3d3;
            width: 100%;
            justify-content: center;
            height: 100%;
            letter-spacing: 2.49px;
            color: #828282;
            &__icon {
              flex-shrink: 0;
              width: 30px;
              height: 30px;
              background: #828282 0% 0% no-repeat padding-box;
              border-radius: 25px;
              color: white;
              text-align: center;
              margin-top: 0.5rem;
              padding-top: 4px;
              &.not-check {
                padding-top: 3px;
              }
              &.mem-col-1 {
                background: #eaa210 0% 0% no-repeat padding-box;
              }
              &.mem-col-2 {
                background: #89d4d2 0% 0% no-repeat padding-box;
              }
              &.mem-col-3 {
                background: #df495c 0% 0% no-repeat padding-box;
              }
              &.mem-col-4 {
                background: #1aceb3 0% 0% no-repeat padding-box;
              }
            }
          }

          .mem-attribute {
            justify-self: left;
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            letter-spacing: 2.49px;
            color: #828282;
            opacity: 1;
          }
        }
      }
    }
    .onclick {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
