.setting {
  overflow: visible;
  padding: 1rem 0;

  .setting-name {
    display: inline-block;
    font-size: 14px;
    padding-right: 5px;
  }

  .help-icon {
    height: 16px;
    width: 16px;
  }

  .setting-input {
    width: 100px;
  }

  .selector {
    width: 300px;
  }

  .slider {
    width: 300px;
  }

  .float-right {
    float: right;
  }

  .setting-unit {
    padding-top: 4px;
    padding-left: 5px;
  }

  &.singleline {
    display: flex;
    align-items: center;
    .setting-unit {
      float: right;
    }
    .setting-input {
      float: right;
      text-align: right;
    }
    .right {
      flex: 4;
    }
  }
}
