.admin-page {
  .container {
    @media screen and (min-width: 1500px) {
      max-width: 1500px;
    }
    @media screen and (min-width: 1700px) {
      max-width: 1600px;
    }
    padding-bottom: 2rem;

    .admin-main-container {
      display: grid;
      grid-template-columns: auto 18rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #0000000f;
      min-height: 75vh;
      margin: 2rem 0;
      padding-bottom: 3rem;
      .admin-orange {
        color: #ff5e14;
      }
      .admin-left-container {
        .admin-button-container {
          display: flex;
          justify-content: center;
          margin-top: 3rem;
        }
        .add-teammate-button {
          width: 12rem;
          height: 3rem;
          background: #ff5e14 0% 0% no-repeat padding-box;
          border-radius: 6px;
          letter-spacing: 0px;
          color: #ffffff;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

          &:hover,
          &:focus {
            cursor: pointer;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
              0px 4px 5px 0px rgba(0, 0, 0, 0.14),
              0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            background-color: darken(#ff5e14, 8%);
          }
          &.disabled {
            &:hover {
              cursor: not-allowed;
              box-shadow: none;
            }
          }
        }
        .title-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          grid-column-start: 1;
          grid-column-end: 5;
          border-bottom: 2px solid #f9f9f9;
          letter-spacing: 0px;
          color: #7f7f7f;
          padding: 0 2rem;
          height: 6rem;
        }
        .label-row {
          width: 100%;
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
          grid-template-rows: 5.4rem;
          padding-left: 3rem;
          padding-right: 1rem;
          .admin-label {
            display: flex;
            align-items: center;
            letter-spacing: 0px;
            color: #7f7f7f;
            &.api {
              padding-left: 1rem;
            }
            &.gb {
              padding-left: 1rem;
            }
          }
          .admin-last-row {
            display: flex;
            align-items: center;
            font-size: 1.1rem;
          }
        }

        .admin-users-list {
          display: flex;
          flex-direction: column;
          .admin-user {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr 0.5fr;
            padding-left: 2rem;
            align-items: center;
            height: 3.8rem;
            border: 2px solid #f9f9f9;
            margin: 0.5rem 0.8rem 0.5rem 0.8rem;
            .admin-user-name {
              color: #7f7f7f;
            }
            .admin-api {
              padding-left: 0.5rem;
            }
            .admin-btn-container {
              height: 100%;
              .admin-delete-btn {
                // in design its white.
                // color: #ffffff;
                color: #7f7f7f;
                height: 100%;
                &:hover {
                  // color: #7f7f7f;
                  color: lighten(#7f7f7f, 10%);
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
      .admin-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #d6d6d6;
        .limits-box {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 6px #0000000f;
          margin: 1rem;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 12rem;
          border-top: 0.3rem solid;
          color: #7f7f7f;
          .limits-balance {
            font-size: 1.25rem;
          }
          .limits-num {
            font-size: 2.5rem;
            font-weight: 700;
          }
          &:nth-child(1) {
            border-top-color: #f39b13;
          }
          &:nth-child(2) {
            border-top-color: #01caff;
          }
          &:nth-child(3) {
            border-top-color: #9a59b5;
          }
          &:nth-child(4) {
            border-top-color: teal;
          }
        }
      }
    }
  }
}
.admin-modal {
  top: 20%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000f;
  max-width: 550px;
  .admin-modal-header {
    border-bottom: none;
    padding-right: 1rem;
    padding-top: 1rem;
  }
  .modal-content {
    border-radius: 0;
  }
  .admin-modal-title {
    margin: 0 1rem 1.5rem 1rem;
    color: #0076c0;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .admin-input-container {
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    .admin-child-container {
      position: relative;
      width: 100%;
      label {
        position: absolute;
        font-size: 1rem;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: 0.1s ease-out;
        transform-origin: left top;
        pointer-events: none;
      }
      input {
        width: 100%;
        font-size: 1rem;
        outline: none;

        border-radius: 5px;
        padding: 1rem 0.7rem;
        color: gray;
        transition: 0.1s ease-out;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000033;
        border-radius: 4px;
      }
      input:focus {
        border: 2px solid #0076c0;
      }
      input:focus + label {
        color: #0076c0;
        top: 0;
        transform: translateY(-50%) scale(0.9);
      }
      input:not(:placeholder-shown) + label {
        top: 0;
        transform: translateY(-50%) scale(0.9);
      }
    }
  }
  .admin-btm-btns {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    .admin-cancel-btn {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #0076c0;
      border-radius: 6px;
      width: 8rem;
      height: 3rem;
      margin-right: 1.5rem;
      font-weight: 600;
      color: #0076c0;

      &:hover,
      &:focus {
        border: 1px solid 0076c0;
        background-color: rgba(25, 118, 210, 0.04);
      }
    }
    .admin-add-btn {
      background: #0076c0 0% 0% no-repeat padding-box;
      border-radius: 6px;
      width: 8rem;
      height: 3rem;
      color: #ffffff;
      letter-spacing: 0px;
      font-weight: 600;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

      &:hover,
      &:focus {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        background-color: darken(#0076c0, 8%);
      }
    }
  }
  .warning-title {
    margin: 1rem 1rem 2rem 1rem;
    letter-spacing: 0px;
    color: #1d1d1d;
  }
  .warning-btn-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-left: 50%;

    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    height: 4rem;
    margin-top: 4rem;
    button {
      color: #0076c0;
      &:hover {
        text-decoration: underline;
        color: darken(#0076c0, 8%);
      }
    }
  }

  .last-warning-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
    .last-warning-row {
      display: flex;
      justify-content: space-around;
      align-content: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      button {
        color: #fff;
        border-radius: 4px;
        background: #60c7c1;
        text-decoration: none;
        transition: all 0.4s;
        line-height: normal;
        min-width: 120px;
        border: none;
        height: 40px;
        border-radius: 3px;
        margin: 0 5px;
        outline: none !important;
      }
      .delete {
        background: #f15e5e;
        &:focus,
        &:hover {
          background: #ee3535;
        }
      }
      .cancel {
        background: #c1c1c1;
        &:focus,
        &:hover {
          background: #a8a8a8;
        }
      }
    }
  }
  .transfer-container {
    margin-top: 2rem;
    .pure-material-radio {
      z-index: 0;
      position: relative;
      display: inline-block;
      color: #212529;

      line-height: 1.5;
    }

    /* Input */
    .pure-material-radio > input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      z-index: -1;
      position: absolute;
      left: -10px;
      top: -8px;
      display: block;
      margin: 0;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: #0076c0;
      outline: none;
      opacity: 0;
      transform: scale(1);
      pointer-events: none;
      transition: opacity 0.3s, transform 0.2s;
    }

    /* Span */
    .pure-material-radio > span {
      display: inline-block;
      width: 100%;
      cursor: pointer;
    }

    /* Circle */
    .pure-material-radio > span::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      margin: 2px 10px 2px 0;
      border: solid 2px; /* Safari */
      border-color: #0076c0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      vertical-align: top;
      transition: border-color 0.2s;
    }

    /* Check */
    .pure-material-radio > span::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: #0076c0;
      transform: translate(5px, 5px) scale(0);
      transition: transform 0.2s;
    }

    /* Checked */
    .pure-material-radio > input:checked {
      background-color: #0076c0;
    }

    .pure-material-radio > input:checked + span::before {
      border-color: #0076c0;
    }

    .pure-material-radio > input:checked + span::after {
      transform: translate(5px, 5px) scale(1);
    }

    /* Hover, Focus */
    .pure-material-radio:hover > input {
      opacity: 0.04;
    }

    .pure-material-radio > input:focus {
      opacity: 0.12;
    }

    .pure-material-radio:hover > input:focus {
      opacity: 0.16;
    }

    /* Active */
    .pure-material-radio > input:active {
      opacity: 1;
      transform: scale(0);
      transition: transform 0s, opacity 0s;
    }

    .pure-material-radio > input:active + span::before {
      border-color: #0076c0;
    }
    /* Disabled */
    .pure-material-radio > input:disabled {
      opacity: 0;
    }
    .pure-material-radio > input:disabled + span {
      color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
      cursor: initial;
    }
    .pure-material-radio > input:disabled + span::before {
      border-color: currentColor;
    }
    .pure-material-radio > input:disabled + span::after {
      background-color: currentColor;
    }

    // something
    .admin-select-container {
      padding-right: 5rem;
      padding-left: 2rem;
    }
    /* ==========  Select Field Variables ========== */
    $color-black: "0,0,0";
    $select-border-color: unquote("rgba(#{$color-black}, 0.12)") !default;
    $select-color: unquote("rgba(#{$color-black}, 0.26)") !default;
    $select-padding: 6px;

    /* ==========  Select Field ========== */

    /* Style Select Field */
    select {
      font-family: inherit;
      background-color: transparent;
      width: 100%;
      padding: $select-padding 0;
      padding-left: 1rem;

      border: none;
      border-bottom: 1px solid $select-border-color;
    }
    .admin-select {
      color: $select-color;
      &.admin-selected {
        color: #212529;
      }
    }
    /* Remove focus */
    select:focus {
      outline: none;
    }

    /* Hide label */
    .mdl-selectfield label {
      display: none;
    }
    /* Use custom arrow */
    .mdl-selectfield select {
      appearance: none;
    }
    .mdl-selectfield {
      position: relative;
      background: #f3f3f3 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 3px #00000033;
      border-radius: 4px;
      color: black;
      &:after {
        position: absolute;
        top: 0.75em;
        right: 0.5em;
        /* Styling the down arrow */
        width: 0;
        height: 0;
        padding: 0;
        content: "";
        border-left: 0.25em solid transparent;
        border-right: 0.25em solid transparent;
        border-top: 0.375em solid $select-border-color;
        pointer-events: none;
      }
    }
  }
  .transfer-btn-container {
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: right;
    padding-right: 1rem;
    .transfer-btn {
      color: #0076c0;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
        color: darken(#0076c0, 8%);
      }
      &.disab {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
