@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
#topnav h1 {
  font-size: 42px !important;
}

#topnav h2 {
  font-size: 36px !important;
}

#topnav h3 {
  font-size: 30px !important;
}

#topnav h4 {
  font-size: 24px !important;
}

#topnav h5 {
  font-size: 20px !important;
}

#topnav h6 {
  font-size: 16px !important;
}

#topnav .display-1 {
  font-size: 80px !important;
}

#topnav .display-2 {
  font-size: 72px !important;
}

#topnav .btn-primary {
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

#topnav .btn-primary:hover,
#topnav .btn-primary:focus,
#topnav .btn-primary:active,
#topnav .btn-primary.active,
#topnav .btn-primary.focus {
  background-color: #2443ac !important;
  border-color: #2443ac !important;
  color: #ffffff !important;
}

#topnav .btn-soft-primary {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: #2f55d4 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

#topnav .btn-soft-primary:hover,
#topnav .btn-soft-primary:focus,
#topnav .btn-soft-primary:active,
#topnav .btn-soft-primary.active,
#topnav .btn-soft-primary.focus {
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-primary {
  border: 1px solid #2f55d4;
  color: #2f55d4;
  background-color: transparent;
}

#topnav .btn-outline-primary:hover,
#topnav .btn-outline-primary:focus,
#topnav .btn-outline-primary:active,
#topnav .btn-outline-primary.active,
#topnav .btn-outline-primary.focus,
#topnav .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

/* .btn-secondary {
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
} */

/* .btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus {
  background-color: #465571 !important;
  border-color: #465571 !important;
  color: #ffffff !important;
} */

/* Top Navigation Sticky Bar */
#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#topnav #topnav .btn-soft-secondary {
  background-color: rgba(90, 109, 144, 0.1) !important;
  border: 1px solid rgba(90, 109, 144, 0.1) !important;
  color: #5a6d90 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

#topnav .btn-soft-secondary:hover,
#topnav .btn-soft-secondary:focus,
#topnav .btn-soft-secondary:active,
#topnav .btn-soft-secondary.active,
#topnav .btn-soft-secondary.focus {
  background-color: #5a6d90 !important;
  border-color: #5a6d90 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-secondary {
  border: 1px solid #5a6d90;
  color: #5a6d90;
  background-color: transparent;
}

#topnav .btn-outline-secondary:hover,
#topnav .btn-outline-secondary:focus,
#topnav .btn-outline-secondary:active,
#topnav .btn-outline-secondary.active,
#topnav .btn-outline-secondary.focus,
#topnav .btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
  box-shadow: 0 3px 5px 0 rgba(90, 109, 144, 0.3);
}

#topnav .btn-success {
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

#topnav .btn-success:hover,
#topnav .btn-success:focus,
#topnav .btn-success:active,
#topnav .btn-success.active,
#topnav .btn-success.focus {
  background-color: #25a06e !important;
  border-color: #25a06e !important;
  color: #ffffff !important;
}

#topnav .btn-soft-success {
  background-color: rgba(46, 202, 139, 0.1) !important;
  border: 1px solid rgba(46, 202, 139, 0.1) !important;
  color: #2eca8b !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

#topnav .btn-soft-success:hover,
#topnav .btn-soft-success:focus,
#topnav .btn-soft-success:active,
#topnav .btn-soft-success.active,
#topnav .btn-soft-success.focus {
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
  color: #ffffff !important;
}

#topnav .btn-outline-success {
  border: 1px solid #2eca8b;
  color: #2eca8b;
  background-color: transparent;
}

#topnav .btn-outline-success:hover,
#topnav .btn-outline-success:focus,
#topnav .btn-outline-success:active,
#topnav .btn-outline-success.active,
#topnav .btn-outline-success.focus,
#topnav .btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
  box-shadow: 0 3px 5px 0 rgba(46, 202, 139, 0.3);
}

#topnav .btn-soft-warning {
  background-color: rgba(241, 116, 37, 0.1) !important;
  border: 1px solid rgba(241, 116, 37, 0.1) !important;
  color: #f17425 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

#topnav .btn-soft-warning:hover,
#topnav .btn-soft-warning:focus,
#topnav .btn-soft-warning:active,
#topnav .btn-soft-warning.active,
#topnav .btn-soft-warning.focus {
  background-color: #f17425 !important;
  border-color: #f17425 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-warning {
  border: 1px solid #f17425;
  color: #f17425;
  background-color: transparent;
}

#topnav .btn-outline-warning:hover,
#topnav .btn-outline-warning:focus,
#topnav .btn-outline-warning:active,
#topnav .btn-outline-warning.active,
#topnav .btn-outline-warning.focus,
#topnav .btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
  box-shadow: 0 3px 5px 0 rgba(241, 116, 37, 0.3);
}

#topnav .btn-soft-info {
  background-color: rgba(23, 162, 184, 0.1) !important;
  border: 1px solid rgba(23, 162, 184, 0.1) !important;
  color: #17a2b8 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

#topnav .btn-soft-info:hover,
#topnav .btn-soft-info:focus,
#topnav .btn-soft-info:active,
#topnav .btn-soft-info.active,
#topnav .btn-soft-info.focus {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
  background-color: transparent;
}

#topnav .btn-outline-info:hover,
#topnav .btn-outline-info:focus,
#topnav .btn-outline-info:active,
#topnav .btn-outline-info.active,
#topnav .btn-outline-info.focus,
#topnav .btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
  box-shadow: 0 3px 5px 0 rgba(23, 162, 184, 0.3);
}

#topnav .btn-soft-danger {
  background-color: rgba(228, 63, 82, 0.1) !important;
  border: 1px solid rgba(228, 63, 82, 0.1) !important;
  color: #e43f52 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

#topnav .btn-soft-danger:hover,
#topnav .btn-soft-danger:focus,
#topnav .btn-soft-danger:active,
#topnav .btn-soft-danger.active,
#topnav .btn-soft-danger.focus {
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-danger {
  border: 1px solid #e43f52;
  color: #e43f52;
  background-color: transparent;
}

#topnav .btn-outline-danger:hover,
#topnav .btn-outline-danger:focus,
#topnav .btn-outline-danger:active,
#topnav .btn-outline-danger.active,
#topnav .btn-outline-danger.focus,
#topnav .btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
  box-shadow: 0 3px 5px 0 rgba(228, 63, 82, 0.3);
}

#topnav .btn-soft-dark {
  background-color: rgba(60, 72, 88, 0.1) !important;
  border: 1px solid rgba(60, 72, 88, 0.1) !important;
  color: #3c4858 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

#topnav .btn-soft-dark:hover,
#topnav .btn-soft-dark:focus,
#topnav .btn-soft-dark:active,
#topnav .btn-soft-dark.active,
#topnav .btn-soft-dark.focus {
  background-color: #3c4858 !important;
  border-color: #3c4858 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-dark {
  border: 1px solid #3c4858;
  color: #3c4858;
  background-color: transparent;
}

#topnav .btn-outline-dark:hover,
#topnav .btn-outline-dark:focus,
#topnav .btn-outline-dark:active,
#topnav .btn-outline-dark.active,
#topnav .btn-outline-dark.focus,
#topnav .btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
  box-shadow: 0 3px 5px 0 rgba(60, 72, 88, 0.3);
}

.btn-muted {
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus {
  background-color: #68788f !important;
  border-color: #68788f !important;
  color: #ffffff !important;
}

#topnav .btn-soft-muted {
  background-color: rgba(132, 146, 166, 0.1) !important;
  border: 1px solid rgba(132, 146, 166, 0.1) !important;
  color: #8492a6 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

#topnav .btn-soft-muted:hover,
#topnav .btn-soft-muted:focus,
#topnav .btn-soft-muted:active,
#topnav .btn-soft-muted.active,
#topnav .btn-soft-muted.focus {
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-muted {
  border: 1px solid #8492a6;
  color: #8492a6;
  background-color: transparent;
}

#topnav .btn-outline-muted:hover,
#topnav .btn-outline-muted:focus,
#topnav .btn-outline-muted:active,
#topnav .btn-outline-muted.active,
#topnav .btn-outline-muted.focus,
#topnav .btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
  box-shadow: 0 3px 5px 0 rgba(132, 146, 166, 0.3);
}

#topnav .btn-soft-light {
  background-color: rgba(248, 249, 252, 0.1) !important;
  border: 1px solid rgba(248, 249, 252, 0.1) !important;
  color: #f8f9fc !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

#topnav .btn-soft-light:hover,
#topnav .btn-soft-light:focus,
#topnav .btn-soft-light:active,
#topnav .btn-soft-light.active,
#topnav .btn-soft-light.focus {
  background-color: #f8f9fc !important;
  border-color: #f8f9fc !important;
  color: #ffffff !important;
}

#topnav .btn-outline-light {
  border: 1px solid #f8f9fc;
  color: #f8f9fc;
  background-color: transparent;
}

#topnav .btn-outline-light:hover,
#topnav .btn-outline-light:focus,
#topnav .btn-outline-light:active,
#topnav .btn-outline-light.active,
#topnav .btn-outline-light.focus,
#topnav .btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
  box-shadow: 0 3px 5px 0 rgba(248, 249, 252, 0.3);
}

#topnav .btn-soft-blue {
  background-color: rgba(47, 85, 212, 0.1) !important;
  border: 1px solid rgba(47, 85, 212, 0.1) !important;
  color: #2f55d4 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

#topnav .btn-soft-blue:hover,
#topnav .btn-soft-blue:focus,
#topnav .btn-soft-blue:active,
#topnav .btn-soft-blue.active,
#topnav .btn-soft-blue.focus {
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-blue {
  border: 1px solid #2f55d4;
  color: #2f55d4;
  background-color: transparent;
}

#topnav .btn-outline-blue:hover,
#topnav .btn-outline-blue:focus,
#topnav .btn-outline-blue:active,
#topnav .btn-outline-blue.active,
#topnav .btn-outline-blue.focus,
#topnav .btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

#topnav .btn-soft-footer {
  background-color: rgba(32, 41, 66, 0.1) !important;
  border: 1px solid rgba(32, 41, 66, 0.1) !important;
  color: #202942 !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

#topnav .btn-soft-footer:hover,
#topnav .btn-soft-footer:focus,
#topnav .btn-soft-footer:active,
#topnav .btn-soft-footer.active,
#topnav .btn-soft-footer.focus {
  background-color: #202942 !important;
  border-color: #202942 !important;
  color: #ffffff !important;
}

#topnav .btn-outline-footer {
  border: 1px solid #202942;
  color: #202942;
  background-color: transparent;
}

#topnav .btn-outline-footer:hover,
#topnav .btn-outline-footer:focus,
#topnav .btn-outline-footer:active,
#topnav .btn-outline-footer.active,
#topnav .btn-outline-footer.focus,
#topnav .btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  color: #ffffff !important;
  -webkit-box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
  box-shadow: 0 3px 5px 0 rgba(32, 41, 66, 0.3);
}

#topnav .btn {
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 6px;
}

#topnav .btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#topnav .btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

#topnav .btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

#topnav a {
  text-decoration: none !important;
}

#topnav p {
  line-height: 1.6;
}

#topnav .section {
  padding: 100px 0;
  position: relative;
}

@media (max-width: 768px) {
  #topnav .section {
    padding: 60px 0;
  }
}

/*Top nav background color and styling*/

.bg-pricing-11 .logo {
  margin-top: 14%;
}

#topnav {
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background-color: transparent;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: white;
}

#topnav .btn {
  font-weight: 600;
  padding: 8px 20px;
  font-family: "Nunito", sans-serif;
  border-radius: 6px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
  border-color: #2f55d4 !important;
}

#topnav .logo {
  float: left;
  margin-left: 5%;
  color: #3c4858 !important;
  padding-bottom: 0px;
  margin-top: 0%;
}

#topnav .logo .l-dark {
  display: none;
}

#topnav .logo .l-light {
  display: inline-block;
}

#topnav .has-submenu.active a {
  color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
  color: #2f55d4 !important;
}

#topnav .has-submenu.active.active .menu-arrow {
  border-color: #2f55d4;
}

#topnav .has-submenu {
  position: relative;
}

#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 14px;
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: #2f55d4;
}

#topnav .navbar-toggle {
  border: 0;
  position: relative;
  width: 60px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 28px 20px;
  height: 18px;
  background-color: white;
}

#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .buy-button {
  float: right;
  margin-right: 5%;
  line-height: 68px;
  background-color: white;
}

#topnav .buy-button > li {
  line-height: initial;
}

#topnav .buy-button .login-btn-primary {
  display: none;
  background-color: white;
}

#topnav .buy-button .login-btn-light {
  display: inline-block;
  background-color: white;
}

#topnav .buy-button .dropdown .dropdown-toggle:after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
  display: none;
  background-color: white;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
  background-color: #2f55d4;
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
  background-color: transparent;
}

#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: white;
}

#topnav .navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
  background-color: white;
}

/* Features button automatically highlighted*/
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  color: #2f55d4 !important;
}

#topnav .navigation-menu > li > a {
  display: block;
  color: #3c4858;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Nunito", sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  color: #2f55d4;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -1px;
  top: 30px;
}

#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

#topnav .menu-extras {
  float: right;
}

#topnav.scroll {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
  color: #3c4858;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #3c4858;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  color: #2f55d4;
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #2f55d4;
}

#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}

#topnav.nav-sticky {
  background: #ffffff;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #3c4858;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #3c4858;
}

#topnav.nav-sticky .buy-button .login-btn-primary {
  display: inline-block;
}

#topnav.nav-sticky .buy-button .login-btn-light {
  display: none;
}

#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
  display: none;
}

#topnav .logo {
  font-weight: 700;
  font-size: 24px;
  padding: 0 0 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (max-width: 993px) {
  #topnav .navigation-menu > li > a {
    font-size: 9px;
    padding: 0px;
  }
}

@media (min-width: 994px) {
  #topnav .navigation-menu > li > a {
    font-size: 9px;
    padding: 1px;
  }
}

@media (min-width: 1090px) {
  #topnav .navigation-menu > li > a {
    font-size: 12px;
    padding: 0px;
  }
}

@media (min-width: 1140px) {
  #topnav .navigation-menu > li > a {
    font-size: 13px;
    padding: 1px;
  }
}

@media (min-width: 1360px) {
  #topnav .navigation-menu > li > a {
    font-size: 13px;
    padding: 14px;
  }
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav
    .navigation-menu
    > .has-submenu
    > .submenu
    > .has-submenu
    > .submenu::before {
    top: 20px;
    margin-left: -35px;
    -webkit-box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
    box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
    border-color: transparent #ffffff #ffffff transparent;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 33px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 30px;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #495057;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #3c4858 !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #2f55d4 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    width: auto;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    min-width: 180px;
    display: inline-block;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 106%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #2f55d4;
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #2f55d4 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-right {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  #topnav .navigation-menu.nav-left {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: 0 !important;
    right: auto !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    left: 45px !important;
    right: auto !important;
  }
  #topnav .buy-button {
    padding-left: 15px;
    margin-left: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  #topnav {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    min-height: 74px;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: 700;
    color: #3c4858 !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #3c4858;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    color: #2f55d4;
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .buy-button .login-btn-primary {
    display: inline-block !important;
  }
  #topnav .buy-button .login-btn-light {
    display: none;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #2f55d4;
  }
  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f1f3f9;
    border-bottom: 1px solid #f1f3f9;
    background-color: #ffffff;
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 16px;
    top: 16px;
  }
  #topnav .buy-button {
    padding: 3px 0;
  }
}

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-toggle {
    display: block;
  }
}

@media (max-width: 375px) {
  #topnav .buy-button {
    display: none;
  }
  #topnav .buy-menu-btn {
    display: block !important;
    margin: 0 10px;
    padding: 10px 20px;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    -webkit-transform: translate3d(0px, -54px, 0px) !important;
    transform: translate3d(0px, -54px, 0px) !important;
  }
  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}
