.common-textbox {
  font-size: 1em;
  padding: 0 8px;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  min-width: 250px;
  min-height: 38px;
  border-width: 1px;
  &:focus {
    border-color: #2684ff;
  }
}

.number-input {
  font-size: 1em;
  padding: 0 8px;
  border: none;
  border-width: 1px;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-color: #2684ff;
  &:focus {
  }
}
