.case-ensemble-main {
  display: flex;
  .model-card-basic-container {
    margin: 0 2rem;
    padding-right: 0.2rem;
    max-height: 45rem;
    overflow: auto;
    .card-instruction {
      border: 2px dashed $success-color;
      border-radius: 1px;
      background-color: white;
      width: 19rem;
      padding: 1rem;
      margin-bottom: 2rem;
      font-size: 0.95rem;
      .new-model-button {
        padding: 0.2rem 0.5rem;
        color: white;
        cursor: pointer;
        display: inline-block;
        background: $primary-color;
        border-radius: 1000px;
      }
    }
    .model-card-basic {
      margin-bottom: 2rem;
      width: 19rem;
    }
  }
  .content {
    flex: 1;
    margin-right: 2rem;
    .ensemble-workspace {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 2rem;
      min-height: 16rem;
      background-color: white;
      box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;
      position: relative;
      .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        z-index: 500;
      }
      .ensemble-control-area {
        width: 32rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: dashed 1px gray;
        text-align: center;
        .dnd-prompt {
          font-size: 1.8rem;
          color: gray;
          margin-bottom: 1rem;
        }
        .header {
          font-size: 1.5rem;
          margin: 1rem 0;
        }
        .prompt {
          margin-bottom: 0.2rem;
        }
        .select {
          div {
            margin: 0.5rem;
          }
          margin-bottom: 2rem;
        }
      }
      .ensemble-drop-area {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-evenly;
        flex-wrap: wrap;
        flex: 1;
        position: relative;
        padding: 0.5rem;
        &.active {
          background-color: #eee;
        }
        .model-card-basic {
          margin: 0 0.5rem 0.5rem 0;
        }
        .drop-prompt {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          color: gray;
          border: dashed 1px #ccc;
          left: 15%;
          top: 15%;
          width: 70%;
          height: 70%;
        }
      }
    }
  }
}

.evaluation-score-tables {
  display: grid;
  grid-template-columns: minmax(30rem, 1fr) minmax(30rem, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
}

.evaluation-score-table {
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  &.selected {
    border: 2px solid $primary-color;
  }
  .header {
    display: flex;
    padding: 1rem 0 0 1rem;
    width: 100%;
    font-weight: bold;
    align-items: center;
    .text {
      margin-right: auto;
    }
    .icon {
      margin: 0 0.5rem;
      cursor: pointer;
      font-size: 1.2rem;
      color: gray;
    }
  }
}

.model-card-basic {
  padding: 1rem;
  background-color: #ddd;
  border-radius: 0.5rem;
  border: solid 0.5px gray;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
  position: relative;
  &.completed {
    background-color: white;
  }
  &.dragging {
    opacity: 0.5;
    border: solid 2px $primary-color;
  }
  &.dragging-preview {
    transform: rotate(10deg);
  }
  .delete-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: #fafafa;
    }
    .text {
      color: gray;
      .toggle-more {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .score-table {
    font-size: 0.75rem;
  }
}

.model-eval-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1200px) {
    padding: 0 2rem;
  }
  .model-eval-top {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000f;
    border: 2px dashed #22caff;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    .model-eval-header {
      padding: 0 2rem;
      display: flex;
      height: 8rem;
      align-items: center;
      width: 100%;
      font-size: 2.25rem;
      color: $light-blue;
      justify-content: center;
      border-bottom: 2px dashed #22caff;
    }
    .model-eval-main {
      display: grid;
      padding: 2rem;
      grid-template-columns: repeat(2, auto);
      justify-items: center;
      .model-eval-tabs {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;

        .model-eval-tab-item {
          margin-bottom: 1rem;
          font-size: 1.25rem;
          padding-bottom: 1rem;
          &:hover {
            cursor: pointer;
            color: $light-blue;
          }
          .active {
            color: $light-blue;
            border-bottom: 3px solid $light-blue;
            padding-bottom: 2px;
          }
        }
      }
      .model-content-cont {
        display: flex;
        justify-self: flex-start;
        flex-direction: column;
        align-items: center;
        .model-eval-scores {
          display: flex;
          margin-top: 1rem;
          @media screen and (max-width: 1200px) {
            flex-direction: column;
          }
          .model-eval-box {
            width: 18rem;
            margin: 1rem;
            box-shadow: 0px 2px 6px #0000000f;
            &:nth-of-type(1) {
              background: #ccf5ff 0% 0% no-repeat padding-box;
              color: #01caff;
              border: 1px solid #ccf5ff;
            }
            &:nth-of-type(2) {
              background: #fdebcf 0% 0% no-repeat padding-box;
              color: #f39b13;
              border: 1px solid #fdebcf;
            }
            &:nth-of-type(3) {
              background: #d5f6e2 0% no-repeat padding-box;
              color: #42d57f;
              border: 1px solid #d5f6e2;
            }
            .model-eval-box-top {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 4rem;
              font-size: 1.25rem;
            }

            .model-eval-box-btm {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 4rem;
              background: #ffffff 0% 0% no-repeat padding-box;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }
  .model-scores-bottom {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000f;
    opacity: 1;
    padding: 3rem 0;
    margin-bottom: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .other-scores {
      width: 60%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      &.other-regression {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        grid-template-columns: repeat( auto-fit, minmax(10rem, 1fr) );
      }
      gap: 1rem;
      column-gap: 2rem;
      .evaluation-score-card {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000000f;
        .name {
          background: #fff;
        }
        .score {
          color: #7d7d7d;
        }
        .text {
          color: #b2b2b2;
        }
      }
    }
  }
}

.orange {
  color: $orange;
}
.grey {
  color: $dark-grey;
}
