.circle-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  font-size: 0.8rem;
}

.busy-overlay {
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(#ddd, 0.5);
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-tip {
	position: fixed;
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 50%;
	bottom: 3rem;
	right: 3rem;
	background-color: #f8cb04;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor:move;
	animation: pulse-page-tip 2s infinite;
	.logo {
	  cursor: pointer;
	  width: 2.2rem;
	  height: 2.2rem;
	  &:hover {
		transform: scale(1.3);
	  }
	}
	.tip-box {
	  cursor:default;
	  visibility: visible;
	  width: 28rem;
	  height: 21rem;
	  text-align: left;
	  border-radius: 6px;
	  padding: 1rem;
	  position: absolute;
	  z-index: 1000;
	  bottom: 100%;
	  left: 50%;
	  margin-left: -24rem;
	  margin-bottom: 2rem;
	  // overflow: auto;
	  background: #ffffff 0% 0% no-repeat padding-box;
	  box-shadow: 6px 5px 6px #0000000f;
	  border: 1px solid #cbcbcb;
	  opacity: 0.95;
	  transition: opacity 1s;
	  .tip-header {
		display: flex;
		justify-content: space-between;
		.tip-header-text {
		  font: 1.5rem;
		  color: #3d465e;
		  padding: 0.25rem;
		}
		.tip-close-button {
		  width: 1rem;
		  height: 1rem;
		  &:hover {
			transform: scale(1.25);
		  }
		}
	  }
	  .hr {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	  }
	  .pagination {
		display: flex;
		justify-content: space-between;
		font-size: 1rem;
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		.left {
		  margin-left: 2rem;
		}
		.right {
		  .clickable {
			color: #0077ff;
			padding: 0.5rem;
			text-decoration: underline;
		  }
		  .nonclickable {
			color: #cacdd1;
			padding: 0.5rem;
			text-decoration: underline;
		  }
		}
	  }
	}
	.tip-box::after {
	  content: "";
	  position: absolute;
	  top: 100%;
	  left: 24rem;
	  margin-left: -0.7rem;
	  border-width: 0.7rem;
	  border-style: solid;
	  border-color: #fefefe transparent transparent transparent;
	  transition: 2s;
	}
  }
  .page-tip:hover {
	animation: none;
  }
  
  @keyframes pulse-page-tip {
	0% {
	  // transform: scale(0.95);
	  box-shadow: 0 0 0 10px #f8cb0439;
	}
  
	70% {
	  // transform: scale(1);
	  box-shadow: 0 0 0 10px #00000000;
	}
  
	100% {
	  // transform: scale(0.95);
	  box-shadow: 0 0 0 10px #f8cb0439;
	}
  }

.control-disabled {
  color: grey;
  &:hover {
    cursor: not-allowed;
  }
}

.spin-reverse {
  animation-direction: reverse;
}

.banner {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.2rem 1.5rem;
  background: white;
  max-height: 5rem;
  overflow: auto;
  .icon {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 1rem;
    height: 1rem;
  }
}

.pagination {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .pagination-inner {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    border-radius: 0.5rem;
    margin-bottom: 0;
    li {
      border-radius: 0;
      a {
        position: relative;
        float: left;
        padding: 0.2rem 0.4rem;
        text-decoration: none;
        color: #333;
        border: 1px solid #ddd;
        margin-left: -1px;
        background-color: white;
      }
      &.active {
        a {
          background-color: $primary-color;
          color: white;
        }
      }
      &.disabled {
        a {
          color: lightgray;
          cursor: not-allowed;
        }
      }
    }
  }
  .pagination-icon {
    margin-right: 0.5rem;
    cursor: pointer;
    &.disabled {
      cursor: inherit;
      color: gray;
    }
  }
}

.test-container {
  width: 100%;
}

.list-container-default {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
  flex: 1;
  div {
    margin: 0 1rem 1rem 1rem;
  }
}

.list-container-grid {
  width: 100%;
  display: grid;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
  div {
    margin: 1rem;
  }
}

.input-with-icon {
  position: relative;
  width: 100%;
  input[type="text"] {
    padding: 0.3rem 2.5rem 0.3rem 0.5rem;
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    color: gray;
    cursor: pointer;
  }
}

.status-message {
  display: inline-block;
  border-radius: 1000px;
  padding: 0.2rem 1rem;
  font-weight: bold;
  cursor: pointer;
  &.with-border {
    border: 2px solid black;
  }
  &.disabled-bg {
    background-color: $disabled-color;
    color: rgba($secondary-color, 0.4);
  }
  &.error-bg {
    background-color: rgba($error-color, 0.4);
    color: $error-color;
  }
  &.warning-bg {
    background-color: rgba($warning-color, 0.4);
    color: $warning-color;
  }
  &.primary-bg {
    background-color: rgba($primary-color, 0.4);
    color: $primary-color;
  }
  &.success-bg {
    background-color: rgba($success-color, 0.4);
    color: $success-color;
  }
}

.ai-tooltip {
  max-width: 35rem;
}

.playground-checkmark-tooltip {
  max-width: 20rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  .multi-line {
    color: gray;
    text-align: left;
  }
}

.popover-wrapper {
  position: relative;
  display: inline-block;
  overflow: visible;
  .popover-content {
    opacity: 0;
    display: none;
    position: absolute;
    cursor: default;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: white;
    color: black;
    border-top: 5px solid $warning-color;
    width: auto;
    flex-direction: column;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    padding: 0.5rem;
    &::after {
      display: none;
      position: absolute;
      z-index: -1;
      content: "";
      right: calc(50% - 10px);
      top: 100%;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #bfbfbf transparent transparent transparent;
    }
    &.error {
      border-top: 5px solid $error-color;
    }
    &.info {
      border-top: 5px solid $primary-color;
    }
    &.show {
      z-index: 100000;
      opacity: 1;
      display: flex;
      &::after {
        display: block;
      }
    }
    &.right-aligned {
      left: auto;
      right: 0;
      transform: translate(0, -100%);
      &::after {
        right: 4rem;
      }
      &.popover-below {
        top: 100%;
        transform: translate(0, 10px);
        &::after {
          top: -15px;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #bfbfbf transparent;
        }
      }
    }
    .message {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      min-width: 20rem;
      margin: 1rem;
      .icon {
        color: $warning-color;
        margin-right: 1rem;
      }
    }
    .action-bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .action {
        font-weight: bold;
        margin: 0 0.5rem 0 1rem;
        text-decoration: underline;
        cursor: pointer;
        color: $primary-color;
      }
    }
  }
  &.hoverable {
    &:hover {
      .popover-content {
        z-index: 100000000;
        opacity: 1;
        display: flex;
        &::after {
          display: block;
        }
      }
    }
  }
}

.img-crossfade {
  position: relative;
  width: 8rem;
  height: 8rem;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
  }
  .top {
    animation-name: crossfade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 0.8s;
    animation-direction: alternate;
  }
}

.file-upload {
  display: flex;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  width: 100%;
  .file-upload-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    .upload-icon {
      color: $primary-color;
      margin-right: 0.5rem;
    }
    &.active {
      border: 2px dashed $primary-color;
      transition: border 0.2s ease-in-out;
    }
    &.disabled {
      color: gray;
      cursor: not-allowed;
      .upload-icon {
        color: gray;
      }
    }
  }
  .select-type {
    min-width: 10rem;
    margin-left: 0.5rem;
  }
}
#trash-upload-col {
  padding-top: 7%;
}
.file-upload-predict {
  display: flex;
  align-items: center;
  flex-direction: column;
  outline: none;
  margin:auto;
  width: 90%;
  .file-upload-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    .upload-icon {
      color: $primary-color;
      margin-right: 0.5rem;
    }
    &.active {
      border: 2px dashed $primary-color;
      transition: border 0.2s ease-in-out;
    }
    &.disabled {
      color: gray;
      cursor: not-allowed;
      .upload-icon {
        color: gray;
      }
    }
  }
  .select-type {
    min-width: 10rem;
    margin-left: 0.5rem;
  }
}

.regular-checkbox-span {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  cursor: pointer;
  label {
    margin: 0;
  }

  .regular-checkbox {
    display: none;
  }

  .regular-checkbox + label {
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .regular-checkbox + label:active,
  .regular-checkbox:checked + label:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .regular-checkbox:checked + label {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  .regular-checkbox:checked + label:after {
    content: "\2714";
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: $success-color;
  }

  .big-checkbox + label {
    padding: 18px;
  }

  .big-checkbox:checked + label:after {
    font-size: 28px;
    left: 6px;
  }

  .regular-checkbox-text {
    white-space: nowrap;
    margin-left: 1rem;
    color: gray;
  }
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

@keyframes crossfade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Customize the label (the container) */
.radio-container {
  display: flex;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.5rem;
  cursor: pointer;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: #2196f3;
      &:after {
        display: block;
      }
    }
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background-color: #eee;
    border-radius: 50%;
    &:hover {
      background-color: #ccc;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 0.5625rem;
      left: 0.5625rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background: white;
    }
  }
}
