.qri-admin-page {
  background-color: white;
  .modal.fade .modal-dialog {
    -webkit-transition: opacity 1s linear;
    -moz-transition: opacity 1s linear;
    -ms-transition: opacity 1s linear;
    -o-transition: opacity 1s linear;
    transition: opacity 1s linear;
  }
  .qri-admin-header {
    background-color: inherit;
    width: 100%;
    height: 90px;
    position: relative;
    .right {
      color: $primary-text-color;
      margin-right: 2rem;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      .icon-download {
        font-size: 1.5rem;
        margin-left: 1rem;
        cursor: pointer;
      }
    }
    .header {
      font-size: 1.5rem;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .qri-admin-container {
    margin-top: 0;
    .table-qri-admin {
      margin-top: 1rem;
      padding: 2rem;
      .admin-dd-div {
        padding: 6px 0;

        &:hover {
          cursor: pointer;
        }
      }
      .delete {
        color: red;
        &:hover {
          color: lighten(red, 25%);
          text-decoration: underline;
        }
      }

      .table-wrap {
        margin: 0 1%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        text-align: center;

        table {
          width: 100%;
          th {
            background: rgba($primary-color, 0.5);
            border-bottom: 2px solid black;
            height: 8rem;
            padding: 0 6px;
          }
          td {
            margin: 0;
            padding: 0.5rem;
            width: 1%;
            &.fit-content {
              width: 0.0000001%;
            }
          }
          tr {
            height: 3rem;
            &:nth-child(even) {
              background: rgba(0, 0, 0, 0.1);
            }
            &.tr-selected {
              background: rgba($primary-color, 0.4);
              color: white;
            }
          }
        }
      }
    }
  }
  .dd-delete {
    color: red;
  }
}
.blue-hyperlink {
  color: #0077ff;
  &:hover {
    color: lighten(#0077ff, 25%);
    text-decoration: underline;
    cursor: pointer;
  }
}
.create-user-form {
  width: 100%;
  .create-user-textbox {
    width: 100%;
    padding: 0.2rem 1rem;
  }
}
