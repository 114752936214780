.faq {
  .container {
    padding: 1rem 0;
    @media screen and (min-width: 1500px) {
      max-width: 1500px;
    }
    .faq-header {
      text-align: center;
      padding: 30px 0;
      letter-spacing: 0;
      color: #646464;
      opacity: 1;
    }
    .faq-main {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem 3rem;
      justify-items: center;
      align-items: center;
      margin-bottom: 6rem;
      .faq-card {
        width: 100%;
        height: 185px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 2px 2px 2px #00000029;
        opacity: 1;
        .faq-card-img-div {
          height: 120px;
          width: 100%;
          display: flex;
          justify-content: center;
          border-bottom: 1px solid #bfbfbf;
          .faq-img {
            width: 80%;
            padding: 10px 0 15px;
          }
        }
        .faq-card-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 65px;
          padding: 0 10px;
          .faq-card-title {
            color: #3f3d56;
            opacity: 1;
            letter-spacing: 0;
          }
          .faq-card-link {
            text-decoration: underline;
            color: #007bff;

            background-color: transparent;
            &:hover {
              color: darken(#007bff, 20%);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.faq-category {
  .container {
    @media screen and (min-width: 1500px) {
      max-width: 1550px;
    }
    @media screen and (min-width: 1700px) {
      max-width: 1650px;
    }
    padding: 0 0 2rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    .button-background {
      background: #f9f9f9;
      height: 80px;
      display: flex;
      align-items: center;
      .faq-back-button {
        padding-left: 1rem;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .faq-category-header {
      letter-spacing: 0;
      color: #3f3d56;
      opacity: 1;
      width: 100%;
      border-bottom: 1px solid #bfbfbf;
      padding-left: 20px;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      &__text {
        font-size: 28px;
      }
      .input-with-icon {
        width: 25rem;
        background: #bfbfbf;
        opacity: 1;
        margin-right: 1rem;
        border-color: #657889;
        border: none !important;
        input {
          &:focus {
            outline: none;
          }
        }
      }
    }
    .faq-category-main {
      display: grid;
      grid-template-columns: 1fr 3fr;
      .faq-category-left {
        padding: 2rem 0;
        box-sizing: border-box;
        .sticky {
          margin: 0;
          position: -webkit-sticky;
          position: sticky;
          top: 20px;
        }
        .faq-category-tab {
          letter-spacing: 0;
          color: #2b333a;
          opacity: 1;
          padding: 16px 0;
          border-radius: 8px;
          &:hover {
            cursor: pointer;
          }
          .faq-category-tab-text {
            padding-left: 1rem;
            color: #2b333a;
          }
          .faq-subtopic {
            margin-top: 0.75rem;
            margin-left: 2rem;
            padding-left: 1rem;
            letter-spacing: 0;
            color: #43505c;
            border-radius: 5px;
            opacity: 1;
            margin-bottom: 0.5rem;
            .sub-a {
              color: #43505c !important;
            }
          }
        }
        .active {
          background: #f6f9fd 0% 0% no-repeat padding-box;
          border-left: 5px solid #0072a9;
        }
      }

      .faq-category-content {
        width: 90%;
        padding: 2rem;
        border-left: 1px solid #bfbfbf;
        line-height: 2.2;
        font-size: 1.2rem;
        h3 {
          font-size: 2rem;
        }
        h4 {
          font-size: 1.5rem;
        }
        h5 {
          font-size: 1.3rem;
        }
        hr {
          margin-top: 4rem;
          margin-bottom: 3rem;
        }
        table,
        td,
        th {
          border: 1px solid #ddd;
          text-align: left;
        }
        table {
          border-collapse: collapse;
          // width: 100%;
        }
        th,
        td {
          padding: 0.25rem;
        }
        figcaption {
          text-align: justify;
          text-justify: inter-word;
          margin: 0.75rem 7rem 0 7rem;
          font-size: 1rem;
          color: #3d465e;
        }
      }
    }
  }
}
