@import "../colors.scss";

.preprocessing-main {
  background: inherit;
  display: flex;
  min-height: 700px;
  align-items: flex-start;

  .preprocessing-panel {
    display: flex;
    position: relative;
    flex-direction: column;
    border: solid 2px #e1e1e1;
    border-radius: 15px;
    background: white;
    width: 25rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: width ease-out 0.1s;
    // max-height: 80rem;
    // overflow-y: auto;

    &.panel-collapsed {
      width: 2.5rem;
      align-items: center;
      padding: 1rem 0;
      transition: width ease-out 0.2s;
      .icon-collapsed {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1.5rem 0.5rem;
      }
    }

    .preprocessing-panel-header {
      height: 90px;
      border-bottom: solid 2px #e1e1e1;
      border-radius: 15px 15px 0 0;
      overflow: hidden;
    }
    .preprocessing-panel-content {
      position: relative;
      flex: 1, 1;
      .preprocessing-plot-panel {
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }
    .preprocessing-panel-toggle {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 10;
      transform: translate(50%, -50%);
    }
  }

  .preprocessing-main-content {
    flex: 1;
    flex-direction: column;
    background: inherit;
    overflow: hidden;
    margin-left: 0.5rem;
    margin-right: 2rem;
    margin-bottom: 10rem;

    .preprocessing-card {
      flex: 1;
      background: white;
      border: solid 2px #e1e1e1;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      position: relative;

      .preprocessing-summary-content {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        max-height: 30rem;
        overflow: auto;
        justify-content: flex-start;
        animation: fadein 0.5s ease-in;
        height: auto;
        &.collapsed {
          display: none;
        }
        .stats-card {
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
          transition: 0.3s;
          border-radius: 1px;
          border-top: solid 3px;
          min-width: 6rem;
          margin: 12px;
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #7f7f7f;
          .stats-value {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }

        @for $i from 1 through length($color-order) {
          .stats-card:nth-child(#{length($color-order)}n + #{$i}) {
            border-top-color: nth($color-order, $i);
          }
        }

        .stats-card:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        }
      }

      .preprocessing-card-content {
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: stretch;
        animation: fadein 0.5s ease-in;
        &.collapsed {
          display: none;
        }
      }
      .preprocessing-card-header {
        width: 100%;
        height: 60px;
        position: relative;
        .histogram-toggle-icon {
          color: #f39b12;
        }
        .preprocessing-card-header-left {
          position: absolute;
          top: 50%;
          display: flex;
          align-items: center;
          margin-left: 2rem;
          transform: translate(0, -50%);
          .header-text {
            font-size: 1.3rem;
            padding-right: 0.7rem;
            font-weight: bold;
            position: relative;
            &.file-modified {
              color: $warning-color;
              &::after {
                content: "*";
                position: absolute;
                right: 0;
                top: 0;
                color: $warning-color;
              }
            }
          }
        }

        .preprocessing-card-header-right {
          position: absolute;
          top: 50%;
          right: 1rem;
          margin-right: 1rem;
          transform: translate(0, -50%);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            width: 1.3rem;
            height: 1.3rem;
            margin-left: 1.3rem;
            cursor: pointer;
          }
        }
      }
      .pppl-viz-empty-view {
        display: flex;
        align-items: center;
        background-color: white;
        margin: 3rem;
        .left {
          flex: 1;
          font-size: 2rem;
          font-weight: bold;
          letter-spacing: 0;
          color: #31394c;
          padding: 2rem;
        }
        .right {
          // height: 15rem;
          width: 20rem;
          transform: scale(1.2);
          margin: 1rem;
        }
      }
    }
  }

  .preprocessing-log {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: black;
    opacity: 0.6;
    .logHeader {
      font: 1rem/1.5rem;
      font-weight: bold;
      letter-spacing: 0.01rem;
      text-align: left;
      margin-bottom: 0.65rem;
    }
    .date {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    .log-detail {
      padding: 0.95rem;
      border-radius: 0.2rem;
      .time-row {
        font: 0.85rem/1.2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.1rem;
        .timestamp {
          float: left;
        }
        .timespan {
          float: right;
        }
      }
      .description {
        font: 0.85rem/1.2rem;
        text-align: left;
        .dot {
          height: 0.3rem;
          width: 0.3rem;
          background-color: $success-color;
          border-radius: 50%;
          display: inline-block;
          margin-left: -0.75rem;
          margin-right: 0.5rem;
          vertical-align: middle;
        }
      }
    }
    :focus {
      outline: 0;
      outline: none;
    }
    .highlight {
      background: #3fcda6 0% 0% no-repeat padding-box;
      color: #ffffff;
      // box-shadow: 0 3px 6px rgba(0,0,0,0.2);
    }
    .log-restore {
      text-align: center;
      text-decoration: underline;
      font: 1rem/1.5rem;
      letter-spacing: 0;
      color: #0076c0;
      margin-top: 0.5rem;
      opacity: 1 !important;
      .icon {
        color: black;
        margin-right: 0.25rem;
        font: 1.2rem/1.5rem;
      }
    }
  }
  .preprocessing-log-done {
    opacity: 1;
  }

  .redo-undo-bar {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      margin-left: 2rem;
      font-weight: bold;
      cursor: pointer;
      .icon {
        margin-right: 0.5rem;
      }
    }
    .redo-undo-icon {
      margin-right: 2rem;
      display: flex;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        color: #ddd;
        border: 2px solid #ddd;
        border-radius: 1rem;
        margin-left: 1rem;
        &.active {
          cursor: pointer;
          color: black;
          border: 0.5px solid black;
        }
      }
    }
  }

  .preprocessing-tab-selector {
    display: flex;
    width: 100%;
    height: 100%;

    .tab-option {
      cursor: pointer;
      outline: 0 !important;
      box-shadow: none !important;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 22px !important;
        height: 22px;
        position: relative;
        color: #4b4d53;
      }

      .text {
        user-select: none;
        font-size: 16px;
        font-weight: normal;
      }
      .selected {
        color: white;
        font-weight: bold;
      }
    }

    .selected {
      background: $secondary-color;
    }
  }
  .preprocessing-autopilot {
    border: 2px solid #f4f4f4;
    border-radius: 2px;
    padding: 1rem;
    font: 1rem/1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0b0d11;
    width: 100%;
    .oneitem {
      color: #0b0d11;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
      }
      &:hover {
        color: #3fcda6;
        transform: scale(1.1);
      }
    }
    .right {
      color: #0b0d11;
      // text-decoration: underline;
      margin-right: 0.2rem;
      .icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
      }
      &:hover {
        color: #3fcda6;
        transform: scale(1.1);
      }
    }
  }

  .preprocessing-accordion {
    border: 1px solid #f4f4f4;
    border-radius: 2px;
    direction: rtl;
    .item {
      border-top: 2px solid #f4f4f4;
      background-color: white;
      direction: ltr;
      .header {
        background-color: white;
        display: flex;
        height: 50px;
        width: 100%;
        &:hover {
          cursor: pointer;
        }
        .button {
          display: flex;
          width: 100%;
          .inner-accordion-header {
            width: 100%;
            height: 100%;
            align-self: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-items: center;

            .icon {
              margin-left: 1rem;
              margin-right: 1rem;
            }
            .name {
              font-size: 16px;
              font-weight: normal;
              margin-right: auto;
            }
            .icon-right {
              margin-right: 1rem;
              &:hover {
                color: green !important;
              }
            }
          }
          .flag {
            color: $error-color !important;
          }

          .text-accordion-selected {
            color: green;
          }
          .header-accordion-selected {
            background: #ddd;
          }
        }
      }
      .panel {
        padding: 20px;
        animation: fadein 0.35s ease-in;
        .primary-button {
          padding: 0.4rem 2.5rem;
        }
      }
    }
  }
}

.preprocess-modal-container {
  @media screen and (min-width: 576px) {
    max-width: 700px !important;
  }
  @media screen and (min-width: 992px) {
    max-width: 1100px !important;
  }

  .preprocess-img-container {
    width: 100%;
    .preprocess-pagi {
      width: 100%;
      display: flex;
      padding: 0.5rem 1rem;
      justify-content: space-between;
      align-items: center;
      .pagi-container {
        align-items: center;
        border-radius: 50%;
        background-color: grey;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          &:hover {
            cursor: auto;
          }
        }
        &.clickable {
          background-color: #0076c0;
          button {
            &:hover {
              cursor: pointer;
            }
          }
          &:hover {
            color: #0076c0;
            background-color: lighten(#0076c0, 15%);
            cursor: pointer;
          }
        }
      }
    }
    .preprocess-img {
      width: 100%;
    }
  }
}
