@import "../colors.scss";

.case-header-bar {
	background-color: #ffffff;
	min-height: 4.5rem;
	margin-bottom: 1rem;
	border-bottom: 0.125rem solid #f2f2f2;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	position: relative;

	.left {
		margin-left: 2rem;
		font-size: 0.875rem;
		font-weight: bold;
		cursor: pointer;
		.icon {
			margin-right: 0.5rem;
		}
	}

	.case-steps {
		display: flex;
		flex-direction: row;
		z-index: 5;
		align-items: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		position: absolute;
		height: 3rem;

		.case-step-item {
			display: flex;
			flex-direction: column;
			font-size: 0.8rem;
			align-items: center;
			justify-content: center;
			color: gray;
			margin: 0.5rem;
			white-space: nowrap;
			&.done {
				color: $primary-color;
			}
			.case-step-icon {
				width:  2rem;
				height: 2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				.done-icon {
					width: 1.2rem;
					height: 1.2rem;
				}
				.current-icon {
					width:  0.6rem;
					height: 0.6rem;
					border-radius: 50%;
					background-color: $primary-color;
					display: flex;
					align-items: center;
					justify-content: center;
					animation: pulse-current-step 3s infinite;
				}
			}
			
		}

		.line {
			transform: translateY(1rem);
			width: 3rem;
			height: 1px;
			border: 1px solid lightgray;
			&.done {
				border-color: $primary-color;
			}
		}
	}

	.right {
		margin-right: 2rem;
		font-size: 0.875rem;
		font-weight: bold;
		text-align: right;
		cursor: pointer;
		.icon {
			margin-left: 0.5rem;
		}
	}
}

.case-sub-header-bar {
	background-color: inherit;
	width: 100%;
	height: 90px;
	position: relative;
	.back {
		color: $primary-text-color;
		font-size: 16px;
		font-weight: bold;
		margin-left: 2rem;
		cursor: pointer;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translate(0, -50%);
	}
	.header {
		font-size: 1.5rem;
		font-weight: bold;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.banner {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border-top: 5px solid $success-color;
		right: 1rem;
		min-width: 20%;
		max-width: 40%;
		min-height: 100%;
		top: 1%;
		animation: fadein 0.3s ease-in;
		&.warning {
			border-top-color: $warning-color;
		}
		&.error {
			border-top-color: $error-color;
		}
	}
}

@keyframes pulse-current-step {
	0% {
		box-shadow: 0 0 0 0.4rem rgba($primary-color, 0.2);
	}
	50% {
		box-shadow: 0 0 0 0.4rem rgba($primary-color, 0.4);
	}
	100% {
		box-shadow: 0 0 0 0.4rem rgba($primary-color, 0.2);
	}
}