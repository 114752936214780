.api-page-header {
  display: flex;
  width: 100%;
  height: 60px;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #b2b2b2;
}

.api-page-container {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  justify-items: center;
  padding: 0 2rem;
  grid-gap: 2rem;
  .table-api {
    width : 100%;
  }
  .table-wrap {
    width: 100%;
    margin: 1rem 1rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  table {
    width: 100%;
    .table-filter {
      width: 100%;
      display: inherit;
    }

    thead {
      tr {
        height: 60px;
        th {
          .header-text {
            color: #7f7f7f;
          }
          border-bottom: 0 3px 6px rgba(0, 0, 0, 0.2);
        }
        th:first-child {
          padding-left: 2rem;
        }
      }
    }
    tbody {
      tr {
        height: 60px;
        &:hover {
          td {
            background-color: rgba($primary-color, 0.05);
          }
        }
      }
      tr:nth-child(odd) {
        background: #f6f6f6 0% 0% no-repeat padding-box;
      }
      .tr-selected {
        background: rgba($primary-color, 0.4) !important;
        color: white;
        border: 3px solid $primary-color;
        td:first-child {
          position: relative;
          &::before {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            height: 100%;
            left: 0.5rem;
            width: 0.000001%;
            content: "✓";
            color: green;
          }
        }
      }
      td:first-child {
        padding-left: 2rem;
      }
      td:last-child {
        padding-right: 1rem;
      }
      td {
        img:hover {
          filter: invert(0.5) sepia(1) saturate(50) hue-rotate(350deg);
        }
        .go-to-api {
          text-align: left;
          color: #007bff;

          &:hover {
            text-decoration: underline;
            color: #0076c0;
            cursor: pointer;
          }
        }
      }
    }
  }

  .api-calls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    .api-calls-box {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      height: 200px;
      border-radius: 1px;
      transition: 0.3s;
      border-top: solid 5px;
      margin: 1rem 0;
      background: #ffffff 0% 0% no-repeat padding-box;
      .calendar-calls {
        padding-top: 1.5rem;
        font-size: 1.5rem;
      }
      .calls-number {
        font-size: 3rem;
      }
      &.first {
        border-color: #00caff;
      }
      &.second {
        border-color: #5a51dd;
      }
      &.third {
        border-color: #f39b12;
      }
      &.remaining {
        border-color: teal;
      }
    }
  }
}
