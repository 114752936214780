.case-model-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    margin-top: 1rem;
  }
}

.case-model-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 30rem;
  .panel-model {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    justify-content: center;
    padding: 2rem 1rem;
    background-color: white;
    align-items: center;
    border-radius: 2rem;
    box-shadow: 0px 2px 6px #0000000f;

    .header {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
      .eval-header-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .prompt {
      margin-bottom: 1.2rem;
    }
    .metrics {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .model-choices-container {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        .model-choices-recommend {
          position: absolute;
          top: 170px;
          color: rgba(67, 66, 93, 0.4);
        }
      }
      .status-message {
        margin: 0.2rem 1rem;
        font-size: 1.1rem;
      }
    }
    .models {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      flex-wrap: wrap;
      .model {
        margin-top: 1rem;
        max-width: 11rem;
        min-width: 8rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #7f7f7f;
      }
    }
  }

  .model-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 49%));
    grid-gap: 2rem 2%;
    align-items: flex-start;
    margin: 2rem;
    margin-bottom: 10rem;
    .model-card {
      box-shadow: 0px 2px 6px #0000000f;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 1rem;
      overflow: hidden;
      animation: fadein 0.5s ease-in;
      .model-training-chart {
        width: 90%;
        height: 18rem;
        margin-bottom: 1rem;
      }
      .score-tab-selector {
        align-self: flex-start;
        padding: 1rem;
        display: flex;
        .tab-option {
          margin-right: 1.5rem;
          cursor: pointer;
          outline: 0;
          box-shadow: none;
          color: gray;
          &.selected {
            font-weight: bold;
            position: relative;
            padding-bottom: 0.5rem;
            .text {
              &::after {
                content: "";
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 1px;
                border-width: 0 0 2px;
                border-style: solid;
              }
            }
          }
        }
      }
      .header {
        display: flex;
        font-size: 1.5rem;
        color: white;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem 2rem;
        .icon {
          cursor: pointer;
        }
        .toggle-score-card {
          margin-left: auto;
          margin-right: 1rem;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 0.9rem;
        }
        .copy-setting {
          margin-left: 1rem;
          text-decoration: underline;
          font-size: 1rem;
          margin-right: auto;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .panel {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        .legend {
          position: absolute;
          display: flex;
          flex-direction: row;
          top: 1rem;
          left: 5%;
        }
        .spinner {
          width: 10rem;
          height: 10rem;
        }
        .optimizing-config-text {
          margin-bottom: 1rem;
        }

        .config-header {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem;
          .mode {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 1rem 2rem;
            .circle {
              font-size: 2rem;
              color: gray;
              background-color: $disabled-color;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0.5rem 0;
              padding: 0.7rem;
              cursor: pointer;
              &.selected {
                color: white;
                background: #00caff 0% 0% no-repeat padding-box;
              }
            }
          }
        }

        .prompt {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin: 0.5rem;
          .icon {
            margin-right: 0.5rem;
          }
          &.autopilot {
            .icon {
              color: $success-color;
            }
          }
          &.running {
            .icon {
              color: $warning-color;
            }
          }
          &.mode-status {
            font-size: 1.3rem;
            font-weight: bold;
          }
        }
        .start-run {
          margin-top: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .text {
            margin-right: 0.5rem;
            &::before {
              content: "*";
              color: red;
            }
          }
          .input {
            width: 5rem;
            margin: 0 1rem;
          }
        }

        #niter-tooltip {
          width: 40%;
        }
        .tuning-progress {
          width: stretch;
          margin: 0 2rem;
        }
        .tuning-status {
          width: stretch;
          margin: 1rem 2rem;
          display: flex;
          color: gray;
          .time-left {
            margin-right: 1rem;
          }
          .job-status {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.icon-better-hyperparams {
  color: gold;
  font-size: 0.7rem;
}

.flip-card {
  background-color: transparent;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
  overflow: hidden;
  &.selected {
    border: 0.2rem solid $primary-color;
    transition: border 1s;
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
    }
    .flip-card-back {
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 1.2rem;
    }
  }
}

.hyperparam-setting-name {
  display: flex;
  align-items: center;
  .name {
    margin-right: 0.3rem;
  }
  .icon {
    font-size: 0.8rem;
  }
  &.static {
    justify-content: center;
  }
}

.text-with-legend {
  font-size: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  &::before {
    content: "---";
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    margin-right: 0.2rem;
    color: gray;
  }
  &.validation {
    &::before {
      color: $primary-color;
    }
  }
  &.test {
    &::before {
      color: $secondary-color;
    }
  }
}

.text-with-underscore {
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 2px dashed gray;
  &.validation {
    border-bottom-color: $primary-color;
  }
  &.test {
    border-bottom-color: $secondary-color;
  }
}
