.case-playground-main {
  padding: 0 2rem;
  display: flex;
  align-items: stretch;
}

.case-playground-nav {
  width: 99.98%;
  height: 99.98%;
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 6rem;
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-top: 1.3rem;
    .playground-nav-header {
      align-self: center;
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      color: #797cb5;
      margin-bottom: 0.5rem;
    }
    .playground-nav-subheader {
      align-self: center;
      font-size: 1.3rem;
      color: #646464;
      margin-bottom: 0.5rem;
    }
    .nav-items {
      margin: 1rem;
      display: grid;
      padding: 0.1rem;
      grid-template-columns: repeat(auto-fit, 12rem);
      column-gap: 2rem;
      row-gap: 2rem;
      justify-content: center;
      align-items: center;
      .playground-nav-card {
        cursor: pointer;
        background-color: white;
        border-radius: 1rem;
        border: 2px solid #dfdfdf;
        box-shadow: 0px 3px 6px #00000029;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        width: 100%;
        height: 10rem;
        position: relative;
        transition: transform 0.25s ease-out;
        .done-page-icon {
          color: lightgray;
          position: absolute;
          font-size: 1.3rem;
          top: 1rem;
          right: 1rem;
          &.done {
            color: $success-color;
          }
        }
        &:hover {
          transform: scale(1.05);
          background-color: #e8ffff;
        }
        .playground-nav-icon {
          height: 3rem;
          margin-bottom: 2rem;
        }
        .playground-nav-label {
          color: #7f7f7f;
        }
      }
    }
  }
  .right {
    height: 48rem;
    width: 49rem;
  }
}

.publish-panel {
  display: flex;
  justify-content: center;
  overflow: auto;
  flex: 1;
  .publish-panel-card {
    padding: 5rem 2rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    background-color: white;
    max-width: 60rem;
    flex: 1;
    box-shadow: 0px 0.3rem 0.5rem #00000029;
    &.published {
      flex-direction: column;
      .published-header {
        font-size: 1.8rem;
        color: #3fcda6;
        margin-bottom: 1.5rem;
      }
      .published-subheader {
        font-size: 1.2rem;
        color: #605b5b;
        margin-bottom: 2rem;
      }
      .img-cases-header {
        width: 40rem;
        margin-bottom: 2rem;
      }
      .notice {
        font-style: italic;
        margin-bottom: 2rem;
        .notice-icon {
          color: gray;
          margin-right: 0.5rem;
        }
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .publish-header {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
      .publish-subheader {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
      }
      .prompt {
        margin-bottom: 1.5rem;
      }
      .publish-button {
        align-self: center;
      }
    }
    .right {
      width: 30rem;
    }
  }
}

.review-panel {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
  .review-card {
    background-color: white;
    border-top: 3px solid #3fcda6;
    border-radius: 0.5rem;
    box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #777;
    .review-card-header {
      border-bottom: 1px solid gray;
      font-weight: bold;
      padding: 1rem;
    }
    .review-info {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #eee;
      padding: 1rem;
      .left {
        width: 35%;
        margin-right: 1rem;
      }
      .right {
        flex: 1;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .preproc-descriptions {
      margin: 0.3rem 1rem;
      padding: 1rem;
      max-height: 15rem;
      overflow-y: auto;
      li {
        margin-bottom: 0.5rem;
      }
    }
    .model-description {
      margin: 0 0.5rem;
      padding: 0.5rem;
    }
  }
}

.evaluation-panel {
  border-radius: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .final-model-evaluation {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    .evaluation-score-card-main {
      padding: 1.5rem;
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
      width: 40rem;
      display: flex;
      flex-direction: column;
      .score-header {
        font-size: 1.1rem;
        font-weight: bold;
      }
      .metric-used {
        font-size: 1rem;
        margin-bottom: 1.5rem;
        .metric-used-label {
          color: gray;
          font-size: 0.9rem;
        }
      }
      .plot-score {
        width: 100%;
        height: 12rem;
      }
    }
    .right {
      margin-left: 2rem;
      flex: 1;
      .other-metrics-prompt {
        color: gray;
        margin-bottom: 1rem;
      }
      .evaluation-score-cards {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
        column-gap: 1rem;
        row-gap: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  .model-evaluation-header {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .regression-panel {
    background-color: white;
    display: flex;
    justify-content: center;
    .plot-regression-scatter {
      width: 60rem;
      height: 50rem;
    }
  }
  .confusion-panel {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .roc-panel {
    background-color: white;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
    justify-items: center;
    align-items: center;
    .plot-roc {
      width: 35rem;
      height: 35rem;
    }
  }
}

.orange-text {
  color: #ff5e00;
  font-size: 2rem;
}

.red-text {
  color: #ff0000;
  font-size: 1.5rem;
}

.explanation-panel {
  border-radius: 0.5rem;
  background-color: white;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  .feature-contribution-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 1rem;
    height: 100%;
    .feature-contribution-left {
      text-align: center;
      padding: 3rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .left-text {
        color: #707070;
        font-weight: 700;
        padding-top: 1rem;
      }
      .setting-input {
        width: 10rem;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        border: 1px solid #707070;
      }
      .tailspin-loader {
        padding-top: 30px;
      }
      .robots-with-flower {
        height: 16rem;
        margin-top: 60px;
        width: 100%;
      }
      .primary-button {
        width: 228px;
        height: 45px;
      }
    }
    .feature-contribution-right {
      padding: 1rem 2rem;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 3rem;
      .feature-contribution-text {
        display: flex;
        align-items: center;
        padding-top: 25px;
        &__icon {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background-color: #3fcda6;
          border-radius: 25px;
          color: white;
          text-align: center;
          margin-top: 10px;
          padding-top: 4px;
        }
        &__text {
          padding-left: 1rem;
          font-size: 1rem;
          color: #707070;
          font-weight: 700;
        }
      }
      .feature-contribution-desc {
        color: #707070;
        font-size: 1rem;
        padding-left: 3rem;
      }
    }
  }
  .opt-results-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 1rem;
    height: 100%;
    .feature-contribution-left {
      text-align: center;
      padding: 3rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .left-text {
        color: #707070;
        font-weight: 700;
        padding-top: 1rem;
      }
      .setting-input {
        width: 10rem;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        border: 1px solid #707070;
      }
      .tailspin-loader {
        padding-top: 30px;
      }
      .robots-with-flower {
        height: 16rem;
        margin-top: 60px;
        width: 100%;
      }
      .primary-button {
        width: 228px;
        height: 45px;
      }
    }
    .feature-contribution-right {
      padding: 1rem 2rem;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 3rem;
      .feature-contribution-text {
        display: flex;
        align-items: center;
        padding-top: 25px;
        &__icon {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background-color: #3fcda6;
          border-radius: 25px;
          color: white;
          text-align: center;
          margin-top: 10px;
          padding-top: 4px;
        }
        &__text {
          padding-left: 1rem;
          font-size: 1rem;
          color: #707070;
          font-weight: 700;
        }
      }
      .feature-contribution-desc {
        color: #707070;
        font-size: 1rem;
        padding-left: 3rem;
      }
    }
  }
  .feature-importance-panel {
    background-color: white;
    display: flex;
    justify-content: center;
    .plot-feature-importance {
      width: 90%;
    }
  }
  .partial-dependency-panel {
    background-color: white;
    .search-plots {
      padding: 2rem 0 1rem 2rem;
      width: 50%;
    }
    .partial-dependency-plots {
      width: 100%;
      background-color: white;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      column-gap: 1rem;
      row-gap: 1rem;
      justify-items: center;
      align-items: center;
      .plot-partial-dependency {
        width: 35rem;
        height: 35rem;
      }
    }
  }
}
// start optimization styles
.opt-table-hyperparams {
  width: 80%;
}

.opt-table-hyperparams .table-wrap table td {
  padding: 0rem 0rem;
  text-align: center;
}
.opt-class-selection {
  display: flex;
  flex-direction: row;
}
.opt-class-label {
  font-weight: bold;
  margin: 6% auto;
  width: 50%;
}

.opt-explanation-panel {
  border-radius: 0.5rem;
  background-color: white;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  .feature-contribution-grid {
    display: grid;
    grid-template-columns: 70% 30%;
    font-size: 1rem;
    height: 100%;
    .feature-contribution-left {
      text-align: center;
      padding: 3rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .left-text {
        color: #707070;
        font-weight: 700;
        padding-top: 1rem;
      }
      .setting-input {
        width: 15rem;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
      }
      .slider {
        width: 19rem;
        width: 90%;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        text-align: center;
      }
      .tailspin-loader {
        padding-top: 30px;
      }
      .robots-with-flower {
        height: 16rem;
        margin-top: 60px;
        width: 100%;
      }
      .primary-button {
        width: 228px;
        height: 45px;
      }
    }
    .feature-contribution-right {
      padding: 1rem 2rem;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 3rem;
      .feature-contribution-text {
        display: flex;
        align-items: center;
        padding-top: 25px;
        &__icon {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background-color: #3fcda6;
          border-radius: 25px;
          color: white;
          text-align: center;
          margin-top: 10px;
          padding-top: 4px;
        }
        &__text {
          padding-left: 1rem;
          font-size: 1rem;
          color: #707070;
          font-weight: 700;
        }
      }
      .feature-contribution-desc {
        color: #707070;
        font-size: 1rem;
        padding-left: 3rem;
      }
    }
  }
  .feature-importance-panel {
    background-color: white;
    display: flex;
    justify-content: center;
    .plot-feature-importance {
      width: 90%;
    }
  }
  .partial-dependency-panel {
    background-color: white;
    .search-plots {
      padding: 2rem 0 1rem 2rem;
      width: 50%;
    }
    .partial-dependency-plots {
      width: 100%;
      background-color: white;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      column-gap: 1rem;
      row-gap: 1rem;
      justify-items: center;
      align-items: center;
      .plot-partial-dependency {
        width: 35rem;
        height: 35rem;
      }
    }
  }
}
.bg-opt {
  background: rgb(216, 79, 0);
}
.opt-card-container {
  width: 95%;
  align-items: flex-start;
  margin: 2rem;
  margin-bottom: 10rem;
  margin-top: 2.5rem;
  .opt-card {
    box-shadow: 0px 2px 6px #0000000f;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 1rem;
    animation: fadein 0.5s ease-in;
    .model-training-chart {
      width: 90%;
      height: 18rem;
      margin-bottom: 1rem;
    }
    .score-tab-selector {
      align-self: flex-start;
      padding: 1rem;
      display: flex;
      .tab-option {
        margin-right: 1.5rem;
        cursor: pointer;
        outline: 0;
        box-shadow: none;
        color: gray;
        &.selected {
          font-weight: bold;
          position: relative;
          padding-bottom: 0.5rem;
          .text {
            &::after {
              content: "";
              width: 100%;
              position: absolute;
              left: 0;
              bottom: 1px;
              border-width: 0 0 2px;
              border-style: solid;
            }
          }
        }
      }
    }
    .header {
      display: flex;
      font-size: 1.5rem;
      color: white;
      justify-content: space-between;
      align-items: center;
      padding: 0.7rem 2rem;
      .icon {
        cursor: pointer;
      }
      .toggle-score-card {
        margin-left: auto;
        margin-right: 1rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 0.9rem;
      }
      .copy-setting {
        margin-left: 1rem;
        text-decoration: underline;
        font-size: 1rem;
        margin-right: auto;
        font-weight: normal;
        cursor: pointer;
      }
    }
    .panel {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      .legend {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 1rem;
        left: 5%;
      }
      .spinner {
        width: 10rem;
        height: 10rem;
      }
      .optimizing-config-text {
        margin-bottom: 1rem;
      }

      .config-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        .mode {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 1rem 2rem;
          .circle {
            font-size: 2rem;
            color: gray;
            background-color: $disabled-color;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.5rem 0;
            padding: 0.7rem;
            cursor: pointer;
            &.selected {
              color: white;
              background: #00caff 0% 0% no-repeat padding-box;
            }
          }
        }
      }

      .prompt {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0.5rem;
        .icon {
          margin-right: 0.5rem;
        }
        &.autopilot {
          .icon {
            color: $success-color;
          }
        }
        &.running {
          .icon {
            color: $warning-color;
          }
        }
        &.mode-status {
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
      .start-run {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .text {
          margin-right: 0.5rem;
          &::before {
            content: "*";
            color: red;
          }
        }
        .input {
          width: 5rem;
          margin: 0 1rem;
        }
      }

      .tuning-progress {
        width: stretch;
        margin: 0 2rem;
      }
      .tuning-status {
        width: stretch;
        margin: 1rem 2rem;
        display: flex;
        color: gray;
        .time-left {
          margin-right: 1rem;
        }
        .job-status {
          margin-left: auto;
        }
      }
    }
  }
}
.prompt {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0.5rem;
  .icon {
    margin-right: 0.5rem;
  }
  &.autopilot {
    .icon {
      color: $success-color;
    }
  }
  &.running {
    .icon {
      color: $warning-color;
    }
  }
  &.mode-status {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
.start-run {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    margin-right: 0.5rem;
    &::before {
      content: "*";
      color: red;
    }
  }
  .input {
    width: 5rem;
    margin: 0 1rem;
  }
}

.tuning-progress {
  width: stretch;
  margin: 0 2rem;
}
.tuning-status {
  width: stretch;
  margin: 1rem 2rem;
  display: flex;
  color: gray;
  .time-left {
    margin-right: 1rem;
  }
  .job-status {
    margin-left: auto;
  }
}
.opt-config-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  .mode {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 2rem;
    .circle {
      font-size: 2rem;
      color: gray;
      background-color: $disabled-color;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
      padding: 0.7rem;
      cursor: pointer;
      &.selected {
        color: white;
        background: #00caff 0% 0% no-repeat padding-box;
      }
    }
  }
}
// end - optimization styles
.uncertainty-panel {
  border-radius: 0.5rem;
  background-color: white;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  .uncertainty-plot-panel {
    background-color: white;
    display: flex;
    justify-content: center;
    .plot-uncertainty {
      width: 90%;
      min-height: 40rem;
    }
  }
  .quantify-uncertainty-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 1rem;
    height: 100%;
    .quantify-uncertainty-left {
      text-align: center;
      padding: 3rem 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      .left-text {
        color: #707070;
        font-weight: 700;
        padding-top: 1rem;
      }
      .max-text {
        color: #707070;
        font-size: 0.75rem;
        font-weight: 400;
        padding-bottom: 1rem;
      }
      .setting-input {
        width: 10rem;
        height: 3rem;
        margin-top: 1rem;
        margin-bottom: 0;
        text-align: center;
        border: 1px solid #707070;
      }
      .tailspin-loader {
        padding-top: 30px;
      }
      .robots-with-flower {
        height: 16rem;
        margin-top: 60px;
        width: 100%;
      }
      .primary-button {
        width: 228px;
        height: 45px;
      }
    }
    .quantify-uncertainty-right {
      padding: 1rem 2rem;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 3rem;
      .quantify-uncertainty-text {
        display: flex;
        align-items: center;
        padding-top: 25px;
        &__icon {
          flex-shrink: 0;
          width: 30px;
          height: 30px;
          background-color: #3fcda6;
          border-radius: 25px;
          color: white;
          text-align: center;
          margin-top: 10px;
          padding-top: 4px;
        }
        &__text {
          padding-left: 1rem;
          font-size: 1rem;
          color: #707070;
          font-weight: 700;
        }
      }
      .quantify-uncertainty-desc {
        color: #707070;
        font-size: 1rem;
        padding-left: 3rem;
      }
    }
  }
}
.prediction-home {
  max-width: 100%;
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  text-align: cen;
  .prediction-home-banner {
    background: #fed605;
    border: #fed605;
    font-size: 13px;
    position: absolute;
    top: 90px;
    right: 10px;
    .close {
      top: -3px;
    }
    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .pred-type-label {
    vertical-align: middle;
  }
  input[type="radio"] {
    vertical-align: middle;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid darkgray;
    border-radius: 50%;
    outline: none;
  }
  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  input[type="radio"]:checked:before {
    background: #43b989;
  }
  .target-name-icon {
    font-size: 1.2rem;
    color: gold;
    margin-right: 0.5rem;
  }
  .pred-home-header {
    color: #605b5b;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .pred-home-header2 {
    color: #605b5b;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    text-align: center;
    &.alert-enter {
      opacity: 0;
      transform: scale(0.9);
    }
    &.alert-enter-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 600ms, transform 600ms;
    }
  }
  .pred-tabs-container {
    display: grid;
    margin: 1rem 5rem;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 70rem;
    column-gap: 1rem;
    margin-bottom: 6rem;
    .pred-tab {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #dfdfdf;
      border-radius: 9px;
      opacity: 1;
      height: 12rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 0.25s ease-out;
      letter-spacing: -0.14px;
      color: #a6a8ab;
      padding-right: 0.5rem;
      .upload-tab-header {
        color: #43b989;
        font-size: 2rem;
        letter-spacing: -0.23px;
      }
      .manual-tab-header {
        color: #f57e6a;
        font-size: 2rem;
        letter-spacing: -0.23px;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.025);
        background-color: #e8ffff;
      }
      &.upload-tab {
        border: 2px solid #43b989;
      }
      &.manual-tab {
        border: 1px solid #f57e6a;
      }
    }
  }
  .pred-content {
    width: 80%;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding-bottom: 10rem;
    margin-bottom: 5rem;

    .pred-manual-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .manual-res-container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #dfdfdf;
        border-radius: 9px;
        opacity: 1;
        width: 100%;
        max-width: 70rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        margin-top: 1rem;
        .manual-res-header {
          font-size: 1.5rem;
          letter-spacing: -0.23px;
          color: #f57e6a;
        }
        .manual-res {
          background: #e9fcf9 0% 0% no-repeat padding-box;
          border: 1px dashed #1aceb3;
          padding: 2.5rem 0;
          width: 30rem;
          display: flex;
          justify-content: center;
          margin: 2rem 0;
          span {
            text-align: left;
            font: Regular 40px/53px Roboto;
            letter-spacing: 0.38px;
            color: #1aceb3;
            opacity: 1;
          }
        }
        .pred-second-btn {
          width: 18rem;
        }
      }
      .manual-header {
        width: 100%;
        height: 6rem;
        text-align: left;
        font-size: 2rem;
        letter-spacing: -0.23px;
        color: #f57e6a;
        opacity: 1;
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .primary-button {
        width: 15rem;
        height: 3rem;
        margin-top: 1rem;
      }
      .pred-features-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        justify-content: center;
        padding-top: 1rem;
        .pred-input-container {
          margin: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2rem;
          .pred-select-child-container {
            position: relative;
            width: 100%;
            &::after {
              position: absolute;
              top: 26px;
              right: 16px;
              /* Styling the down arrow */
              width: 0;
              height: 0;
              padding: 0;
              content: "";
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid rgba(0, 0, 0, 0.12);
            }
            .select-text {
              position: relative;
              appearance: none;
              -webkit-appearance: none;
              outline: none;
              font-size: 1rem;
              width: 100%;
              padding: 1rem 0.7rem;
              color: gray;
              border: 1px solid #ddd;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 1px 3px #00000033;
              border-radius: 4px;
              &:focus {
                outline: none;
                border: 1px solid #0076c0;
                ~ {
                  .select-label {
                    color: #0076c0;
                    top: 0;
                    transition: 0.1s ease-out;
                    transform: translateY(-50%) scale(0.9);
                  }
                  .select-bar {
                    &:before {
                      width: 50%;
                    }
                    &:after {
                      width: 50%;
                    }
                  }
                }
              }
              &:valid {
                ~ {
                  .select-label {
                    top: 0;
                    transition: 0.1s ease-out;
                    transform: translateY(-50%) scale(0.9);
                  }
                }
              }
            }

            .select-label {
              font-size: 1rem;
              position: absolute;
              pointer-events: none;
              transition: 0.1s ease-out;
              top: 20px;
              left: 0;
              color: gray;
              padding: 0 0.3rem;
              margin: 0 0.5rem;
              transform-origin: left top;
              pointer-events: none;
              top: 50%;
              transform: translateY(-50%);
              background-color: white;
            }
            /* BOTTOM BARS ================================= */
            .select-bar {
              position: relative;
              display: block;
              width: 350px;
              &:before {
                content: "";
                height: 2px;
                width: 0;
                bottom: 1px;
                position: absolute;
                background: #2f80ed;
                transition: 0.2s ease all;
                left: 50%;
              }
              &:after {
                content: "";
                height: 2px;
                width: 0;
                bottom: 1px;
                position: absolute;
                background: #2f80ed;
                transition: 0.2s ease all;
                right: 50%;
              }
            }
          }
          .pred-child-container {
            position: relative;
            width: 100%;
            // copied
            .pred-input {
              width: 100%;
              font-size: 1rem;
              outline: none;
              padding: 1rem 0.7rem;
              color: gray;
              background: #ffffff 0% 0% no-repeat padding-box;
              box-shadow: 0px 1px 3px #00000033;
              border-radius: 4px;
              &:focus {
                border: 1px solid #0076c0;
                + {
                  label {
                    color: #0076c0;
                    top: 0;
                    transform: translateY(-50%) scale(0.9);
                  }
                }
              }
              &:not(:placeholder-shown) {
                + {
                  label {
                    top: 0;
                    transform: translateY(-50%) scale(0.9);
                  }
                }
              }
            }
            .pred-input-label {
              position: absolute;
              font-size: 1rem;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background-color: white;
              color: gray;
              padding: 0 0.3rem;
              margin: 0 0.5rem;
              transition: 0.1s ease-out;
              transform-origin: left top;
              pointer-events: none;
            }
          }
        }
      }
    }
    .pred-first-btn {
      margin-bottom: 6rem;
    }
  }

  .prediction-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: auto;
    .header {
      display: flex;
      background-color: white;
      .left {
        padding: 2rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        align-items: center;
        justify-content: center;
        border-right: 1px solid gray;
        .prompt {
          margin-bottom: 1rem;
        }
        .label {
          margin-right: 0.5rem;
          color: gray;
        }
        .target-name {
          margin-right: 3rem;
        }

        .link {
          cursor: pointer;
          color: $primary-color;
          text-decoration: underline;
          margin-right: 0.3rem;
        }
      }
      .right {
        display: flex;
        flex-grow: 0.3;
        justify-content: center;
        align-items: center;
        padding: 2rem 2rem;
      }
    }

    .file-details {
      margin-top: 2rem;
      box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      padding: 2rem;
      background-color: white;
      .file-details-header {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        padding: 0 2rem 1rem 2rem;
        justify-content: space-between;
        border-bottom: 4px solid gray;
        .download-icon {
          cursor: pointer;
        }
      }
    }
  }
}

.files-panel {
  margin-top: 2rem;
  background-color: white;
  padding-bottom: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .files-table {
    width: 95%;
  }
  .prediction-trash-icon {
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: gray;
  }
  .download-icon {
    cursor: pointer;
    font-size: 1.2rem;
    color: gray;
  }
  .file-upload-progressbar {
    margin-right: 1rem;
    min-width: 4rem;
    max-width: 20rem;
    flex: 1;
  }
  .file-upload-error-icon {
    color: $error-color;
    margin-right: 0.3rem;
  }
  .file-upload-error-text {
    text-decoration: underline;
    cursor: pointer;
    color: $error-color;
    .file-upload-error-popover {
      padding: 1rem;
      min-width: 20rem;
      max-height: 15rem;
      overflow-y: auto;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      .file-upload-error-popover-header {
        display: flex;
        align-items: center;
        color: $error-color;
        margin-bottom: 1rem;
      }
    }
  }
}

.report-panel {
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.2);
  background-color: white;
  flex: 1;
  min-height: 35rem;
  flex-direction: column;
  overflow-x: auto;
  .playground-content-header {
    .report-popover-header {
      display: flex;
      font-size: 1.3rem;
      color: gray;
      white-space: nowrap;
      align-items: center;
      margin-bottom: 1rem;
      .add-report-image {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
      }
    }
    .prompt {
      text-align: center;
      color: gray;
      font-weight: bold;
      margin-bottom: 0.7rem;
    }
  }
  .report-landing {
    padding: 2rem 2rem;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    font-weight: 500;
    .report-image {
      width: 16rem;
      margin-top: 2rem;
    }
  }
}
.file-upload-error-icon {
  color: $error-color;
  margin-right: 0.3rem;
}
.file-upload-error-text {
  text-decoration: underline;
  cursor: pointer;
  color: $error-color;
  .file-upload-error-popover {
    padding: 1rem;
    min-width: 20rem;
    max-height: 15rem;
    overflow-y: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    .file-upload-error-popover-header {
      display: flex;
      align-items: center;
      color: $error-color;
      margin-bottom: 1rem;
    }
  }
}

#pred-file-upload-container {
  margin-top: 2rem;
  max-width: 100%;
  padding-left: 4rem;
  padding-bottom: 0.4rem;
  margin-bottom: 1.5rem;
}
#pred-file-upload-row {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  margin-right: 0.1rem;
}
#left-col-upload {
  padding: 1.5rem 3rem;
}
#right-col-upload {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
}
#upload-error-container {
  margin-top: 0.5rem;
}
#file-upload-trash {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
  margin: auto;
}
#target-col-quicktip {
  font-weight: bold;
  margin-left: 0.3rem;
}
#quick-tips-container .quick-tip {
  margin-bottom: 0.7rem;
}
#quick-tips-heading {
  font-style: italic;
  font-weight: bold;
}
.dataset-link {
  cursor: pointer;
  color: #3c86ff;
  text-decoration: underline;
  margin-right: 0rem;
  margin-left: 0.3rem;
}
#predict-select-header {
  margin-bottom: 1.3rem;
  font-size: 1.2rem;
}
.pred-type-select {
  margin-right: 0.3rem;
  border-color: pink;
}
#pick-file-select {
  margin-right: 1rem;
}
// model optimization radio styles
#model-opt-max {
  margin-right: 1rem;
  font-size: 1rem;
}
.opt-label {
  vertical-align: middle;
  font-size: 1rem;
}
.opt-select {
  margin-right: 0.3rem;
  border-color: pink;
  height: 15px;
  width: 15px;
}
#opt-header {
  margin: 1rem 0rem 0.2rem 0rem;
}

.playground-busy-panel {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  .warning {
    font-size: 1.3rem;
    color: red;
  }
}

.playground-content-header {
  display: flex;
  background-color: white;
  padding: 1rem 2rem;
  width: 100%;
  .left {
    max-width: 50%;
    margin-right: 2rem;
    .header {
      font-size: 1.3rem;
      color: gray;
    }
    .description {
      font-size: 1rem;
      color: black;
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    .report {
      width: 190px;
      height: 51px;
      text-align: center;
      padding-top: 13px;
    }
  }
}

.evaluation-score-card {
  padding: 1rem 0.5rem;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 1px 0px 2px 1px rgba(189, 189, 189, 0.75);
  .name {
    border-radius: 50%;
    margin-right: 1rem;
    width: 4.5rem;
    height: 4.5rem;
    background-color: yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
  }
  .score-grid {
    display: grid;
    grid-template-columns: 1fr 4rem;
    column-gap: 1rem;
    row-gap: 0.3rem;
    align-items: stretch;
    .text {
      color: gray;
    }
    .score {
      font-weight: bold;
      text-align: center;
    }
  }
}

.api-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: auto;
  .prediction-api-card {
    position: relative;
    background-color: white;
    flex: 1;
    overflow: hidden;
    width: 90%;
    max-width: 55rem;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 0px 2px 1px rgba(189, 189, 189, 0.75);
    border-radius: 1rem;
    .api-header {
      background-color: #eee;
      font-size: 1.5rem;
      padding: 1rem 2rem;
      color: #555;
    }
    .api-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: auto;
      padding: 1rem 2rem;
      .api-usage-panel {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
      .api-subheader {
        font-size: 1.1rem;
        font-weight: bold;
      }
      .api-key-info {
        display: grid;
        grid-template-columns: 7rem 1fr;
        grid-template-rows: 1fr 1fr;
      }
      .api-text {
        color: gray;
      }
      .api-copy-icon {
        color: $primary-color;
        cursor: pointer;
      }
      .api-trash-icon {
        color: $error-color;
        cursor: pointer;
      }
      .api-key-label {
        margin-top: 0.9rem;
        color: gray;
      }
      .api-key-button {
        margin-top: 1rem;
      }
      .Collapsible {
        overflow: auto;
        width: 100%;
        border-bottom: 1px solid gray !important;
        padding: 0.8rem 0.5rem 0.5rem 0.5rem;
      }
    }
  }
}

.api-usage-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  height: 8rem;
  width: 10rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  border-top: solid 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: gray;
  .number {
    color: black;
    font-size: 1.3rem;
  }
}

@for $i from 1 through length($color-order) {
  .api-usage-card:nth-child(#{length($color-order)}n + #{$i}) {
    border-top-color: nth($color-order, $i);
  }
}

@for $i from 1 through length($color-order) {
  .evaluation-score-card:nth-child(#{length($color-order)}n + #{$i}) {
    .name {
      background-color: rgba(nth($color-order, $i), 0.2);
      color: nth($color-order, $i);
    }
  }
}
