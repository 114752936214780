@import "../colors.scss";

.header-outer {
  .product-header {
    background-color: #ffffff;
    color: #fff;
    border-top: solid 5px $product-color;
    box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 6px rgba(59, 64, 69, 0.1);
    z-index: 999;
    margin-bottom: 3px;

    .product-name {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      // font-family: 'Muli', sans-serif;
      text-align: left;
      white-space: nowrap;
      color: $product-color;
      letter-spacing: 0.2px;
      margin-right: auto;
      &:hover {
        cursor: pointer;
      }
    }

    .logo {
      height: 35px;
      padding: 0px;
      padding-left: 20px;
      margin-top: 6px;
      margin-right: 1rem;
      &:hover {
        cursor: pointer;
      }
    }
    .navbar-nav {
      margin-top: 10px;
      .nav-item {
        padding: 0 15px;
        border-right: 1px solid #f2f2f2;
        height: 100%;
        .supp-dropdown {
          margin-top: 2px;
        }
        .my-account-nav {
          margin-top: -8px;
        }
        &.logout {
          color: #666;
          cursor: pointer;
        }
        &.active {
          color: #0076c0 !important;
          border-bottom: 4px #0076c0 solid;
          a {
            color: #0076c0 !important;
          }
        }
        .active {
          color: $product-color !important;
          border-bottom: 3px #0076c0 solid;
        }
        .nav-link {
          padding: 0;
        }
        .nav-text {
          &:hover {
            color: #0076c0;
            text-decoration: none;
          }
        }
        .dropdown-toggle {
          &:after {
            &:hover {
              color: orange;
            }
          }
        }
        .help-form {
          width: 22rem;
          color: #000000;
          text-align: left;
          .help-header {
            text-align: center;
            color: #646464;
            padding-bottom: 2rem;
          }
          .primary-button {
            padding: 8px 0;
          }
          &.sent {
            margin-bottom: 0;
          }
        }

        a {
          color: #646464;
          // font-family: "Proxima Nova";
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .header-outer {
    .product-header {
      .nav-item {
        .my-account-nav {
          margin: 6px 0 !important;
        }
      }
    }
  }
}
