.visualization {
  display: flex;
  height: 100%;
  padding-left: 15px;

  .nav-outer-container {
    width: 200px;
    height: 100%;
  }

  .visualization-container {
  	/*height: 100%;*/
  	width: 100%;
    padding: 15px 0px 0px 15px;
    overflow-y: auto;

  	.loading-background { 
		height: 100%;
		background: #FFFFFF;
  	}
  }
}
