/* Template Name: Pricepeg - ootstrap 5 Pricing Table Template 
   Author: Themesdesign
   Version: 1.0
   Auther : https://codecanyon.net/user/themesdesign
   File Description: Main Css file of the template
*/

/*--- Google Font ---*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap");

#outer-container .membership2 div {
  font-family: "Inter", sans-serif;
}

.membership2 h3.text-capitalize {
  font-size: 1.9rem;
}

.membership2 .text-muted {
  font-size: 1.1rem;
  line-height: 1.7rem;
}

.membership2 .pricing-content li {
  font-size: 1.2rem;
}

.membership2 .price h1 {
  font-size: 2.7rem;
}

.membership2 .price h6 {
  font-size: 1.1rem;
  font-weight: normal !important;
}

.membership2 .btn {
  font-size: 1.2rem;
}

.pricing-box .pricing-title h6,
.pricing-active .pricing-title h6 {
  font-size: 1.1rem;
}

// START PRICING - RESIZE COLUMNS
// .pricing-box-personal,
// .pricing-box-pro,
// .pricing-box-contact {
//   min-height: 480px;
// }

.price-banner-contact {
  display: table;
}
.price-banner-contact-text {
  display: table-cell;
  vertical-align: middle;
}

.price-banner-contact {
  min-height: 84px;
}
// END PRICING - RESIZE COLUMNS

.membership2 {
  background: url("../../static/images/SML-Graphic-BG.png");
  background-size: 100%;
  background-color: #f9f9f9;
  overflow-x: hidden !important;
  color: #0d547b;
  display: flex;
  height: 100vh;
  position: relative;
}

.bg-pricing-11 .text-primary {
  color: #0076c0 !important;
}

.bg-pricing-11 .bg-primary {
  background-color: #0076c0 !important;
}

.bg-pricing-11 .bg-primary .band {
  background-color: #363636 !important;
}

.bg-pricing-11 .text-light {
  color: #f8f9fa !important;
}

.bg-pricing-11 .btn-primary {
  background: #0076c0;
  border-color: #363636 !important;
}

.bg-pricing-11 .btn-primary:hover,
.bg-pricing-11 .btn-primary:focus,
.bg-pricing-11 .btn-primary:active,
.bg-pricing-11 .btn-primary.active,
.bg-pricing-11 .btn-primary.focus,
.bg-pricing-11 .btn-primary:not(:disabled):not(.disabled):active,
.bg-pricing-11 .btn-primary:not(:disabled):not(.disabled):active:focus,
.bg-pricing-11 .btn-primary .open > .dropdown-toggle.btn-primary {
  background: #cd5747;
  border-color: #363636;
  box-shadow: 0px 8px 20px -6px rgba(100, 100, 100, 0.6);
}

.bg-pricing-11 .btn-outline-primary {
  color: #0076c0;
  border-color: #0076c0;
}

.bg-pricing-11 .btn-outline-primary:hover,
.bg-pricing-11 .btn-outline-primary:focus,
.bg-pricing-11 .btn-outline-primary:active,
.bg-pricing-11 .btn-outline-primary.active,
.bg-pricing-11 .btn-outline-primary.focus,
.bg-pricing-11 .btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-pricing-11 .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.bg-pricing-11 .btn-outline-primary .open > .dropdown-toggle.btn-primary {
  background: #cd5747;
  border-color: #363636;
  color: #ffffff;
  box-shadow: 0px 8px 20px -6px rgba(100, 100, 100, 0.6);
}

.bg-pricing-11 .pricing-box,
.bg-pricing-11 .pricing-active {
  border: 1px solid;
  border-color: rgba(104, 113, 121, 0.25);
  transition: all 0.35s ease;
}

.bg-pricing-11 .price {
  color: #0f105e;
  background-color: #e5e3e3;
  transition: all 0.35s ease;
  width: 100%;
  height: 100%;
}

.bg-pricing-11 .pricing-content li {
  padding: 5px 0;
  color: #687179;
}

.bg-pricing-11 .pricing-box:hover .price {
  color: #ffffff;
  background-color: #0076c0;
}

.bg-pricing-11 .pricing-active {
  border-top: 3px solid #000000;
}

.bg-pricing-11 .pricing-active .price {
  color: #ffffff;
  background-color: #f5c814;
}

.bg-pricing-11 .pricing-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  height: 100px;
}

.bg-pricing-11 .pricing-badge .badge {
  float: right;
  transform: rotate(45deg);
  right: -66px;
  top: 25px;
  position: relative;
  width: 200px;
  margin: 0;
  padding: 7px 10px;
}

.bg-pricing-11 .pricing-box::before {
  content: "";
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 0;
  border-bottom: 3px solid #000000;
  opacity: 1;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.bg-pricing-11 .pricing-box:hover::before {
  width: 100%;
}

.trial-link {
  color: #0076c0;
  margin-right: 0.5rem;
}

.trial-link:hover,
.trial-link:focus,
.trial-link:active {
  color: #cd5747;
}

.membership2 .logo {
  margin-top: 13%;
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
  height: auto;
  display: block;
  padding-bottom: 35px;
}

#most-pop-badge {
  background-color: #cd5747 !important;
  color: #fff;
  font-weight: normal;
  font-size: 0.8rem;
}
