.footer2 {
  background: #f4f4f4 !important;
  padding: 60px 20px 20px 1px;
  position: relative;
  color: #adb5bd;
}

.footer2 .mt-4 {
  font-family: "Nunito", sans-serif;
  color: #646464;
}

.footer2 .logo-footer {
  font-size: 22px;
}

.footer2 .footer-head {
  letter-spacing: 1px;
  font-weight: 500 !important;
  font-family: "Nunito", sans-serif;
}

.footer2 .text-blue {
  color: #0076c0 !important;
}

.footer2 .foot-subscribe .form-control {
  background-color: #27314f;
  border: 1px solid #27314f;
  color: #f8f9fc;
}

.footer2 .foot-subscribe .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer2 .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer2 .foot-subscribe input::-webkit-input-placeholder {
  color: #adb5bd;
}

.footer2 .foot-subscribe input:-ms-input-placeholder {
  color: #adb5bd;
}

.footer2 .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

.footer2 .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer2 .text-foot {
  color: #646464;
}

.footer2 .footer-list {
  margin-bottom: 0;
}

.footer2 .footer-list li {
  margin-bottom: 10px;
  font-family: "Nunito", sans-serif;
}

.footer2 .footer-list li a {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.footer2 .footer-list li a:hover {
  color: #e6e8ea;
}

.footer2 .footer-list li:last-child {
  margin-bottom: 0;
}

.footer2.footer-border,
.footer2.footer-bar {
  border-top: 1px solid #283353;
}

.footer2.footer-bar {
  padding: 30px 0;
}
