.cases {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 10px;

  .case-filter-outer {
    padding: 30px 40px 25px 40px;

    .case-filter-headers {
      display: grid;
      grid-template-columns: 400px 200px;
      grid-column-gap: 20px;
      padding-bottom: 5px;
      font-size: 12px;
    }

    .case-filter {
      display: grid;
      grid-template-columns: 400px 200px;
      grid-column-gap: 20px;

      .name-filter {
        font-size: 14px;
        padding-left: 10px;
      }
    }
  }

  .overview {
    width: 100%;
    height: 100%;
    position: relative;

    .case-header {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      width: 575px;
      height: 50px;
      line-height: 50px;
      background: #ffffff;
      margin-left: 40px;
      position: relative;

      .tab-option {
        padding-left: 16px;
        font-size: 14px;
        cursor: pointer;
        outline: 0 !important;
        box-shadow: none !important;

        .tab-option-icon-background {
          height: 24px;
          display: inline-block;
          position: relative;
          width: 24px;
          background: #ffffff;
          top: 13px;
          margin-left: 0px;
          border-radius: 20px;
        }

        .tab-option-icon {
          width: 24px;
          height: 12px;
          top: -14px;
          position: relative;
          color: #4b4d53;
        }

        .tab-option-text {
          padding-left: 8px;
        }
      }

      .tab-option:nth-child(2) {
        border-left: solid 1px #d2d2d2;
        border-right: solid 1px #d2d2d2;
      }

      .selected {
        background: #4b4d53;

        .tab-option-text {
          color: #ffffff;
          font-weight: bold;
        }
      }
    }

    .new-case-button {
      float: right;
      height: 40px;
      width: 120px;
      background: #0076c0;
      color: white;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      cursor: pointer;
      margin-right: 40px;
      margin-top: 40px;
    }

    .case-table {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 2px 40px 0px 40px;
      overflow-y: auto;

      .case-item {
        display: grid;
        grid-template-columns: 300px auto 130px 230px;
        margin-bottom: 15px;
        min-height: 120px;
        background: #ffffff;
        box-shadow: 1px 0px 2px 1px rgba(189, 189, 189, 0.75);
        color: #4b4d53;
        cursor: pointer;
        outline: 0 !important;

        .case-item-one {
          .name {
            color: #525252;
            font-weight: bold;
            padding-top: 25px;
            margin-left: 40px;
            font-size: 24px;
          }

          .owner {
            margin-left: 40px;
            font-size: 16px;
            padding-top: 4px;
            font-weight: 300;
          }
        }

        .case-item-two {
          .created {
            color: #ea813d;
            padding-top: 25px;
            font-size: 15px;
          }

          .description {
            padding-top: 5px;
            font-size: 15px;
          }
        }

        .case-item-three {
          .step-bar {
            text-align: center;
            padding-top: 52px;
            width: 130px;

            .outer-bar {
              height: 11px;
              background: #ebebeb;
              width: 100%;
              border: solid 2px #e1e1e1;
              position: relative;

              .inner-bar {
                position: relative;
                height: 100%;
              }

              .finalized {
                width: 100%;
                background: #12aa04;
              }

              .customize {
                width: 33%;
                background: #ffce00;
              }

              .execution {
                width: 66%;
                background: #ffce00;
              }

              .failed {
                width: 66%;
                background: red;
              }

              .visualize {
                width: 100%;
                background: #ffce00;
              }
            }

            .step-bar-upper-text {
              font-size: 12px;
              padding-top: 4px;
            }

            .step-bar-lower-text {
              font-size: 12px;
            }
          }
        }

        .case-item-four {
          padding-right: 15px;

          .action-button {
            position: relative;
            float: right;
            display: inline-block;
            width: 35px;
            height: 35px;
            border-radius: 35px;
            margin-top: 43px;
            margin-right: 8px;
            background: #868686;
            outline: 0 !important;
            box-shadow: none !important;

            .action-button-icon {
              height: 21px;
              width: 21px;
              color: #ffffff;
              margin-top: 7px;
              margin-left: 7px;
            }
          }

          .action-button:active {
            background: #a0a0a0;
          }

          .favorited {
            background: #dc3545;
          }

          .favorited:active {
            background: #e4606d;
          }

          .shared {
            background: #0303dd;
          }

          .shared:active {
            background: #1717fc;
          }
        }
      }

      .case-item:hover {
        background: #eafbff;
      }
    }
  }

  .workflow {
    position: relative;
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 100px;
      line-height: 100px;
      background: #ffffff;

      .prev {
        font-weight: bold;
        font-size: 20px;
        width: 0px;
        color: #a3a6a8;
        display: inline-block;
        float: left;
        margin-left: 25px;
        width: 70px;
        border: none;
      }

      .nav-bar {
        position: relative;
        left: calc(50% - 380px);
        width: 580px;
        height: 100%;
        display: inline-block;

        .step-item {
          display: inline-block;
          vertical-align: top;
          width: 90px;
          height: 100%;
          text-align: center;

          .inner {
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-rows: 70% 20%;
            grid-template-areas: "circle" "text";

            .circle {
              grid-area: circle;
              background: white;
              border: solid 3px #ededed;
              border-radius: 40px;
              height: 40px;
              margin-left: 25px;
              margin-top: 20px;
              width: 40px;
              display: block;
            }

            .text {
              grid-area: text;
              color: #a3a6a8;
              z-index: 10;
              line-height: 13px;
              height: 26px;
              display: block;
              position: relative;
              font-size: 13px;
            }
          }
        }

        .line {
          display: inline-block;
          width: 155px;
          position: relative;
          height: 3px;
          background: #ededed;
          vertical-align: top;
          top: 39px;
        }
      }

      .save-button {
        position: relative;
        border: none;
        float: right;
        top: 30px;
        margin-right: 25px;
        border-radius: 5px;
        right: 0px;
        height: 40px;
        width: 120px;
        color: #33356f;
        border: solid 1px #33356f;
        display: inline-block;
        background: white;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        line-height: 40px;
      }

      .continue-button {
        position: relative;
        border: none;
        float: right;
        top: 30px;
        margin-right: 25px;
        border-radius: 5px;
        right: 0px;
        height: 40px;
        width: 120px;
        background: #33356f;
        display: inline-block;
        color: white;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        line-height: 40px;
      }
    }

    .initialize {
      position: relative;
      background: #f6f9ff;
      width: 100%;
      height: calc(100% - 100px);
      text-align: center;

      .select-container {
        position: relative;
        left: calc(50% - 150px);
        width: 300px;
      }

      .initialize-header {
        padding-top: 35px;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #4b4d53;
      }
    }

    .modify {
      position: relative;
      background: #f6f9ff;
      width: 100%;
      height: calc(100% - 100px);
      overflow-y: auto;

      .modify-header {
        padding-top: 35px;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #4b4d53;
      }

      .settings-groups {
        position: relative;
        left: 25%;
        width: 50%;

        .Collapsible {
          padding-bottom: 15px;
          cursor: pointer;
        }

        .Collapsible__trigger {
          display: block;
          position: relative;
          height: 90px;
          background: #ffffff;
          font-size: 40px;
          padding-left: 30px;
          line-height: 90px;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);

          &:after {
            font-family: "FontAwesome";
            content: "\f107";
            position: absolute;
            right: 21px;
            top: 0px;
            display: block;
            transition: transform 300ms;
          }

          &.is-open {
            &:after {
              transform: rotateZ(180deg);
            }
          }
        }

        .group {
          width: 100%;
          background: #ffffff;

          .settings-header {
            font-size: 18px;
            margin-left: 32px;
            border-bottom: solid 0px white;
            padding-top: 20px;
            padding-bottom: 8px;
          }

          .setting {
            display: grid;
            grid-template-columns: 50% 50%;
            border-top: solid 1px #8989894f;
            margin: 0px 30px 0px 30px;
            padding: 15px;

            .setting-name {
              color: #898989;
              line-height: 38px;
            }
          }

          .settings-inner-table {
            color: #898989;
            padding: 5px 30px 15px 30px;
          }
        }

        .settings-outer-table {
          color: #898989;
        }

        .new-row-button {
          position: relative;
          left: calc(50% - 11px);
        }
      }
    }

    .execute {
      position: relative;
      background: #f6f9ff;
      width: 100%;
      height: calc(100% - 100px);

      .execute-header {
        padding-top: 35px;
        padding-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #4b4d53;
        text-align: center;
      }

      .execute-button {
        position: relative;
        border: none;
        left: calc(50% - 60px);
        border-radius: 5px;
        height: 40px;
        width: 120px;
        background: #33356f;
        display: inline-block;
        color: white;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }

      .step-status {
        width: 340px;
        margin: auto;
        padding-top: 25px;

        .step {
          display: grid;
          grid-template-columns: 280px 60px;
          height: 60px;
          font-size: 18px;
          line-height: 60px;
          text-align: left;
        }

        .successful {
          color: green;
        }

        .failed {
          color: red;
        }
      }
    }

    .finalize {
      position: relative;
      background: #f6f9ff;
      width: 100%;
      height: calc(100% - 100px);
    }
  }
}
